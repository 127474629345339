import React from 'react'
import JewelryCardsContainer, { jewelryDataType } from '../public/home/HomeExploreCards/JewelryCardsContainer'
import { Drawer } from '@mui/material';


interface drawerProps {
    open: boolean;
    handleClose: ()=> void;
    data: jewelryDataType[]
}

export default function JewelryDrawer({open, handleClose, data}: drawerProps) {

  return (
    <Drawer
        anchor="top"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { top: "80px"},
        }}
      >
        
        <JewelryCardsContainer data={data} onClose={handleClose}/>
      </Drawer>
  )
}





