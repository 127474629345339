import {
  Drawer,
  Box,
  Typography,
  Toolbar,
  IconButton,
  Divider,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";

interface DrawerProps {
  filter:any;
  setFilter:(selectedFilter:FilterProps)=>void;
  open: boolean;
  onClose: () => void;
}

export interface FilterProps {
  color: string[];
  // keyword: string;
  metal: string[];
  price: string | null;
  stone: string[];
  sortBy: string | null;
}

interface accordion {
  sortBy: boolean;
  metal: boolean;
  stone: boolean;
  color: boolean;
  price: boolean;
}
const filterObjects = {
  sortBy: [
    "Top Match",
    "New Arrivals",
    "Price: Low to High",
    "Price: High to Low",
  ],
  metal: [
    "925 Sterling Silver",
    "Yellow gold Vermeil",
    "Rose gold Vermeil",
    "9k Yellow gold",
    "9k Rose gold",
    "9k White gold",
    "14k Yellow gold",
    "14k Rose gold",
    "14k White gold",
    "18k Yellow gold",
    "18k Rose gold",
    "18k White gold"
  ],
  stone: [
    "Diamond",
    "Ruby",
    "Sapphire",
    "Emerald",
    "Amethyst",
    "Aquamarine",
    "Garnet",
    "Topaz",
    "Peridot",
    "Opal",
    "Citrine",
    "Tanzanite",
    "Tourmaline",
    "Lapis Lazuli",
    "Moonstone",
    "Turquoise",
    "Onyx",
    "Zircon",
    "Spinel",
    "Jade"
  ],
  color: [
    "Red",
    "Blue",
    "Green",
    "Yellow",
    "Purple",
    "Pink",
    "White",
    "Black",
    "Orange",
    "Brown",
    "Multicolored"
  ],
  price: ["Under 150", "150 - 300", "300 - 500", "500+"]
};

const ProductListFilter: React.FC<DrawerProps> = ({
  filter,
  setFilter,
  open,
  onClose
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<accordion>({
    sortBy: false,
    metal: false,
    stone: false,
    color: false,
    price: false,
  });

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const handleFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    type: keyof FilterProps,
    value: string
  ) => {
    event.preventDefault();
    let newFilter = Object.assign({}, filter);
    // let newFilter = { ...selectedFilter };
    if (type !== "sortBy" && type !== "price") {
      if (newFilter[type] !== null) {
        const index = newFilter[type]?.indexOf(value);
        if (index !== -1) {
          newFilter[type] = [
            ...newFilter[type]?.slice(0, index),
            ...newFilter[type]?.slice(index + 1),
          ];
        } else {
          newFilter[type] = [...newFilter[type]!, value];
        }
      }
    } else {
      newFilter[type] = value;
    }

    setFilter(newFilter);
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 1,
        style: {
          backgroundColor: "var(--merino-color)",
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "var(--dark-color)" }}
          >
            Filters
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider
          sx={{ borderColor: "var(--dark-color)", margin: "0 32px 32px" }}
        />
        <Box sx={{ padding: "0 40px", }}>
          {Object.keys(filterObjects).map((keyName: any) => (
            <Accordion
              key={keyName}
              expanded={expanded[keyName as keyof accordion]}
              onChange={handleChange(keyName as keyof accordion)}
            >
              <AccordionSummary
                expandIcon={
                  expanded[keyName as keyof accordion] ? (
                    <RemoveIcon />
                  ) : (
                    <AddIcon />
                  )
                }
                aria-controls="shortBy-content"
                id="shortBy-header"
                sx={{ textTransform: "capitalize" }}
              >
                {keyName}
                {(keyName === "sortBy" || keyName === "price") &&
                  filter[keyName as keyof accordion] && (
                    <>{": " + filter[keyName as keyof accordion]}</>
                  )}
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {filterObjects[keyName as keyof FilterProps].map((item) => (
                    <ListItem key={item} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={(event) => handleFilter(event, keyName, item)}
                        dense
                      >
                        <ListItemIcon>
                          {keyName === "sortBy" || keyName === "price" ? (
                            <Radio
                              edge="start"
                              checked={
                                !!(
                                  filter[
                                  keyName as keyof FilterProps
                                  ] === item
                                )
                              }
                              tabIndex={-1}
                              disableRipple
                              sx={{
                                padding: 0,
                                "& .MuiSvgIcon-root": {
                                  color: "var(--softPink-color)",
                                },
                              }}
                            />
                          ) : (
                            <Checkbox
                              edge="start"
                              checked={
                                Array.isArray(
                                  filter[keyName as keyof FilterProps]
                                )
                                  ? filter[
                                    keyName as keyof FilterProps
                                  ]?.includes(item)
                                  : false
                              }
                              tabIndex={-1}
                              disableRipple
                              sx={{
                                padding: 0,
                                "& .MuiSvgIcon-root": {
                                  color: "var(--softPink-color)",
                                },
                              }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default ProductListFilter;
