import {
  Box,
  Typography,
  Button,
  IconButton,
  Rating,
  Chip,
  Skeleton,
} from "@mui/material";
import "./NewArrival.scss";
import { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { post } from "../../../layout/Api/Api";
import { IMG_URL } from "../../../constants/ApiConstants";
import { useCountryContext } from "../../Public";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet1: {
    breakpoint: { max: 1024, min: 650 },
    items: 3,
  },
  tablet2: {
    breakpoint: { max: 650, min: 425 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 425, min: 0 },
    items: 1,
  },
};

export const NewArrivalCards: React.FC<any> = ({ ...data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { currentCountry } = useCountryContext();
  const { i18n } = useTranslation();
  const [remainingTime, setRemainingTime] = useState<any>(null);
  const [textColor, setTextColor] = useState("green");

  const endTimeIs = data?.saleEndTime;
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const timeDifference = endTimeIs - now;
  
      if (timeDifference > 0) {
        // Calculate remaining days
         const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  

        if (daysLeft > 7) {
          // If more than 7 days, show "Nothing"
          setRemainingTime("");
          setTextColor("transparent"); // You can adjust this color as needed
        } else if (daysLeft > 3) {
          // If more than 3 days but less than or equal to 7 days
          setRemainingTime(`Sale ends in: ${daysLeft} days`);
          setTextColor("green");
        } else if (daysLeft < 3 && daysLeft >= 0) {
          // If less than 3 days
          const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
          const hours = totalHours; // Total hours remaining
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000); // Remaining seconds
  
          setTextColor("red");
          setRemainingTime(
            `Sale ends in: ${hours}h:${minutes < 10 ? `0${minutes}` : minutes}m:${seconds < 10 ? `0${seconds}` : seconds}s`
          );
        }
      } else {
        // If the sale has ended
        // setRemainingTime("Sale has ended");
        // setTextColor("gray");
      }
    }, 1000); // Update every 1 second
  
    return () => clearInterval(intervalId);
  }, [endTimeIs]);

  //Implement Only Few Left / Only 4-3-2-1 left / Out of stock /
  const stockQuantity = data?.stock_quantity;
  const stockDisplay =
    stockQuantity === 0
      ? "out of stock"
      : stockQuantity < 5
        ? `Only → ${stockQuantity} left`
        : stockQuantity <= 10
          ? "Only Few Left"
          : stockQuantity;

  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Box className="cards_new_arrival">
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => navigate(`/product/${data.abbrevation}`)}
        sx={{ cursor: "pointer", width: "100%", height: "350px" }}
      >
        {!imageLoaded && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "black",
            }}
          >
            <ReactLoading
              type="bubbles"
              color="var(--softPink-color)"
              height={100}
              width={80}
            />
          </Box>
        )}
        <img
          src={
            !isHovered
              ? data?.image_url
                ? IMG_URL + data?.image_url
                : "/images/demo/new_arrival/0-LABGROWNDIAMONDSBEZEL-LargeSoloDiamondBracelet-14K-TopDown_444.jpg"
              : data?.image_url
                ? IMG_URL + data?.image_url
                : "/images/demo/new_arrival/1-LABGROWNDIAMONDSBEZEL-LargeSoloDiamondBracelet-14K-Stack_018.jpg"
          }
          alt="img"
          onLoad={handleImageLoad}
          style={{
            display: imageLoaded ? "block" : "none",
            width: "100%",
            height: "100%",
          }}
        />
      </Box>

      <Box>
        <Typography
          variant="body1"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            lineClamp: 1,
            textOverflow: "ellipsis",
          }}
        >
          {data?.sku_title}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            component="p"
            sx={{
              color: "red",
              fontSize: { xs: "12px", sm: "14px" },
            }}
          >
            {stockDisplay}
          </Typography>

          {data && Number(data?.rating) > 0 ? (
            <Rating
              defaultValue={Number(data?.rating) || 0}
              precision={0.5}
              readOnly
              sx={{ "& .MuiRating-icon": { fontSize: "15px" } }}
            />
          ) : null}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          {data?.sales_price && data?.sales_price !== data?.base_price ? (
            <Box
              sx={{
                display: "flex",
                background: "#f4f4f461",
                gap: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "13px", sm: "15px" },
                  paddingRight: "5px",
                  color: "#0f743b",
                  fontWeight: 600,
                }}
              >
                {currentCountry?.currencyIcon}
                {data.sales_price.toFixed(2)}
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "13px", sm: "14px" },
                  textDecoration: "line-through",
                  color: "#b5b5b5",
                  fontWeight: "400",
                  fontStyle: "italic",
                }}
              >
                {currentCountry?.currencyIcon}{data?.base_price.toFixed(2)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: { xs: "60px", sm: "80px" },
                  fontWeight: "bolder",
                  color: "gray",
                }}
              >
                {`(${data?.discount ?? "0"}%Off)`}
              </Typography>

              <Chip
                sx={{
                  height: "25px",
                  width: { xs: "80px", sm: "100px" },
                  backgroundColor: "#a0e193",
                  fontSize: { xs: "8px", sm: "10px" },
                }}
                label="FREE shipping"
              />
            </Box>
          ) : (
            // No sale, show only the base price
            <Typography
              variant="h6"
              sx={{
                paddingRight: "25px",
                fontSize: { xs: "13px", sm: "15px" },
                color: "var(--Price-color)",
                fontWeight: "bold",
              }}
            >
              {currentCountry?.currencyIcon}{data?.base_price.toFixed(2)}
            </Typography>
          )}

          <Typography
            component="p"
            sx={{
              color: textColor, // Use the dynamic text color
              fontSize: { xs: "12px", sm: "14px" },
            }}
          >
            {remainingTime ? `Sale ends in : ${remainingTime}` : null}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

function NewArrival() {
  const isMounted = useRef(false);
  const [loading, setLoading] = useState<Boolean>(false);
  const [productList, setProductList] = useState<any[]>([]);
  const navigate = useNavigate();
  const pagination = {
    limit: 20,
    page: 1,
  };
  const { currentCountry } = useCountryContext();
  const { i18n } = useTranslation();
  const handleSearch = () => {
    if (currentCountry) {
      setLoading(true);
      try {
        post(
          {
            keyword: "",
          },
          `products/search?page=${pagination.page}&limit=${pagination.limit}&country=${currentCountry.code}&language=${i18n.language}`
        ).then((response: any) => {
          if (response && response.status === "OK") {
            setProductList(response.data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } catch {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // if (isMounted.current) {
    handleSearch();
    // } else {
    //   isMounted.current = true;
    // }
  }, [currentCountry]);
  return (
    <>
      {!loading && (
        <Box className="new_arrival">
          <Typography variant="h4" className="heading" mb={2}>
            New in
          </Typography>
          <Button
            variant="contained"
            className="arrival_button"
            onClick={() => navigate("/products")}
          >
            Shop All new
          </Button>
          <Box>
            <Carousel responsive={responsive} infinite>
              {productList.length > 0 &&
                productList.map((item, index) => (
                  <NewArrivalCards key={index} {...item} />
                ))}
            </Carousel>
          </Box>
        </Box>
      )}
    </>
  );
}

export default NewArrival;
