import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the product
interface Product {
  id: string;
  name: string;
  // Add other properties based on your product structure
}

// Define the context type
interface ProductContextType {
  products: Product[];
  addProducts: (newProducts: Product[]) => void;
}

// Create the context
const ProductContext = createContext<ProductContextType | undefined>(undefined);

// Create a provider component
export const ProductProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [products, setProducts] = useState<Product[]>([]);
  // console.log("proudct context call")

  const addProducts = (newProducts: Product[]) => {
    setProducts((prevProducts) => [...prevProducts, ...newProducts]);
  };

  return (
    <ProductContext.Provider value={{ products, addProducts }}>
      {children}
    </ProductContext.Provider>
  );
};

// Custom hook to use the Product Context
export const useProductContext = (): ProductContextType => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProductContext must be used within a ProductProvider");
  }
  return context;
};
