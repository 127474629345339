import { useEffect, useState } from "react";
import { useCountryContext } from "../../Public";
import { useTranslation } from "react-i18next";
import { Box, Chip, IconButton, Rating, Skeleton, Typography } from "@mui/material";
import ReactLoading from "react-loading";
import { IMG_URL } from "../../../constants/ApiConstants";
import { Favorite } from "@mui/icons-material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import "./ProductList.scss";





interface cartProps {
    data: any;
    addToWishList: () => Promise<boolean>;
    handleProductDetails: () => void;
  }


const ProductCards: React.FC<cartProps> = ({
    data,
    addToWishList,
    handleProductDetails,
  }) => {
    const { currentCountry } = useCountryContext();
    const [heartState, setHeartState] = useState(data?.inWishlist);
    const { i18n } = useTranslation();
    const [remainingTime, setRemainingTime] = useState("");
    const [textColor, setTextColor] = useState("green");
    const [imageLoaded, setImageLoaded] = useState(false);
  
    //Implement Only Few Left / Only 4-3-2-1 left / Out of stock /
    const stockQuantity = data?.stock_quantity;
    const stockDisplay =
      stockQuantity === 0
        ? "out of stock"
        : stockQuantity < 5
        ? `Only → ${stockQuantity} left`
        : stockQuantity <= 10
        ? "Only Few Left"
        : stockQuantity;
  
    const endTimeIs = data?.saleEndTime;
    useEffect(() => {
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const timeDifference = endTimeIs - now;
  
        if (timeDifference > 0) {
          // Calculate remaining days
          const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
          if (daysLeft > 7) {
            // If more than 7 days, show "Nothing"
            setRemainingTime("");
            setTextColor("transparent"); // You can adjust this color as needed
          } else if (daysLeft > 3) {
            // If more than 3 days but less than or equal to 7 days
            setRemainingTime(`Sale ends in: ${daysLeft} days`);
            setTextColor("green");
          } else if (daysLeft < 3 && daysLeft >= 0) {
            // If less than 3 days
            const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
            const hours = totalHours; // Total hours remaining
            const minutes = Math.floor(
              (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
            ); // Remaining minutes
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000); // Remaining seconds
  
            setTextColor("red");
            setRemainingTime(
              `Sale ends in: ${hours}h:${
                minutes < 10 ? `0${minutes}` : minutes
              }m:${seconds < 10 ? `0${seconds}` : seconds}s`
            );
          }
        } else {
          // If the sale has ended
          // setRemainingTime("Sale has ended");
          // setTextColor("gray");
        }
      }, 1000); // Update every 1 second
  
      return () => clearInterval(intervalId);
    }, [endTimeIs]);
  
    return (
      <Box className="productList_card">
        {data?.image_url ? (
          <Box className="card_img-section">
            <Box onClick={handleProductDetails}>
              {!imageLoaded && (
                <Box
                  sx={{
                    height: { xs: "200px", sm: "200px", lg: "300px" },
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "black",
                  }}
                >
                  <ReactLoading
                    type="bubbles"
                    color="var(--softPink-color)"
                    height={100}
                    width={80}
                  />
                </Box>
              )}
              <Box
                component="img"
                src={IMG_URL + (data?.image_url || "")}
                alt="img"
                sx={{
                  display: imageLoaded ? "block" : "none",
                  width: "100%",
                  height: { xs: "200px", sm: "200px", lg: "300px" }, // Responsive height based on Skeleton
                  objectFit: "cover",
                }}
                onLoad={() => setImageLoaded(true)}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src =
                    "/images/demo/new_arrival/0-GANNIXMEJURI-Clover25mmHoop-SS-Angled_198.jpg";
                  setImageLoaded(true); // Ensure skeleton is hidden even on error
                }}
              />
            </Box>
            <IconButton
              aria-label=""
              className="heart_icon"
              size="small"
              onClick={() => {
                addToWishList().then((response: any) => {
                  if (response) {
                    setHeartState(!data.inWishlist);
                    data.inWishlist = !data.inWishlist;
                  }
                });
              }}
            >
              {heartState ? (
                <Favorite sx={{ color: "#ff0000" }} />
              ) : (
                <FavoriteBorderIcon />
              )}
            </IconButton>
          </Box>
        ) : (
          // <Skeleton  sx={{ height: { xs: "200px", sm: "200px", lg: "300px" } }} variant="rounded" width="100%" />
          <Box
            sx={{
              height: { xs: "200px", sm: "200px", lg: "300px" },
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "black",
            }}
          >
            <ReactLoading
              type="bubbles"
              color="var(--softPink-color)"
              height={100}
              width={80}
            />
          </Box>
        )}
  
        <Box pt={1} onClick={handleProductDetails}>
          {data?.sku_title ? (
            <Typography
              variant="body1"
              sx={{
                display: "-webkit-box", // Flexbox for truncating text
                overflow: "hidden", // Hides overflowing text
                WebkitBoxOrient: "vertical", // Required for line clamping
                WebkitLineClamp: 2, // Limits to 2 lines
                lineClamp: 2, // For non-WebKit browsers (fallback)
                textOverflow: "ellipsis",
              }}
            >
              {data?.sku_title}
            </Typography>
          ) : (
            <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
          )}
  
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component="p"
              sx={{
                color: "red",
                fontSize: { xs: "12px", sm: "14px" },
              }}
            >
              {stockDisplay}
            </Typography>
  
            {data && Number(data.rating) ? (
              <Rating
                defaultValue={Number(data?.rating) || 0}
                precision={0.5}
                readOnly
                sx={{ "& .MuiRating-icon": { fontSize: "15px" } }}
              />
            ) : // <Typography variant="body2">No review available</Typography>
            null}
          </Box>
  
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {data?.sales_price && data?.sales_price !== data?.base_price ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  background: "#f4f4f461",
                  gap: "5px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "11px", sm: "13px" },
                    color: "#0f743b",
                    fontWeight: 600,
                  }}
                >
                  {currentCountry?.currencyIcon}
                  {data?.sales_price.toFixed(2)}
                </Typography>
  
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "11px", sm: "13px" },
                    textDecoration: "line-through",
                    color: "#b5b5b5",
                    fontWeight: "400",
                    fontStyle: "italic",
                    "@media (max-width: 600px)": {
                      paddingRight: "5px",
                      paddingLeft: "5px",
                    },
                  }}
                >
                  {currentCountry?.currencyIcon}
                  {data?.base_price.toFixed(2)}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: "11px", sm: "13px" },
                    fontWeight: "bolder",
                    color: "gray",
                  }}
                >
                  {`(${data?.discount ?? "0"}%Off)`}
                </Typography>
  
                <Chip
                  sx={{
                    height: "20px",
                    backgroundColor: "#a0e193",
                    fontSize: { xs: "9px", sm: "10px" },
                    "& .MuiChip-label": {
                      padding: "0 5px",
                    },
                  }}
                  label="FREE shipping"
                />
              </Box>
            ) : (
              // No sale, show only the base price
              <Typography
                variant="h6"
                sx={{
                  paddingRight: "25px",
                  // paddingLeft: "10px",
                  fontSize: { xs: "13px", sm: "15px" },
                  color: "var(--Price-color)",
                  "@media (max-width: 600px)": {
                    paddingRight: "5px",
                    // paddingLeft: "5px",
                  },
                }}
              >
                {currentCountry?.currencyIcon}
                {data?.base_price.toFixed(2)}
              </Typography>
            )}
  
            <Typography
              component="p"
              sx={{
                color: textColor, // Use the dynamic text color
                fontSize: { xs: "12px", sm: "14px" },
              }}
            >
              {remainingTime}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

export default ProductCards;