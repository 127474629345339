import {
  useContext,
  useState,
  createContext,
  MutableRefObject,
  useRef,
  ReactNode,
} from "react";
import ProductCart from "../products/ProductCart/ProductCart";

interface CartProviderProps {
  children: ReactNode;
}
interface cartType {
  setProductCartDrawerState: (state: boolean) => void;
  handleBagListRef: MutableRefObject<any>;
}

const cartContext = createContext<cartType>({
  setProductCartDrawerState: () => {},
  handleBagListRef: { current: null },
});

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const handleBagListRef = useRef<any>(null);

  return (
    <>
      <cartContext.Provider
        value={{ setProductCartDrawerState: setOpen, handleBagListRef }}
      >
        {children}
      </cartContext.Provider>
      <ProductCart
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        handleBagListRef={handleBagListRef}
      />
    </>
  );
};

export const useCartContext = () => useContext(cartContext);
