import { useNavigate } from "react-router-dom";
import { appShortName } from "../../../constants/ApiConstants";
import { useCallback, useEffect, useState } from "react";
import { post, get } from "../../../layout/Api/Api";
import { useCartContext } from "../../context/CartContext";
import { useTranslation } from "react-i18next";
import { useCountryContext } from "../../Public";

export const useAddToWishList = () => {
  const navigate = useNavigate();
  const { setProductCartDrawerState, handleBagListRef } = useCartContext();
  const { fetchWishList } = useGetWishList(); // Call the custom hook

  const addToWishList = useCallback(
    async (productId: string): Promise<boolean> => {
      const wishlistItem = { id: productId, quantity: 1, size: "10" };
      const user = JSON.parse(localStorage.getItem("divineuser") || "null");

      try {
        if (user && user.id) {
          const response = await post(wishlistItem, `wishlist/add/${productId}`);
          if (response && response.status === "OK") {
            console.log("Item successfully added to wishlist on the server");
            await fetchWishList(); // Refresh the wishlist
            handleBagListRef.current?.getWishListWithWishListTab();
            setProductCartDrawerState(true);
            return true;
          } else {
            console.error("Failed to add item to wishlist:", response);
            return false;
          }
        } else {
          navigate("/signup");
          let wishlist: any[] = JSON.parse(
            localStorage.getItem(appShortName + "wishlist") || "[]"
          );
          const existingItemIndex = wishlist.findIndex(
            (item) => item.id === productId
          );
          if (existingItemIndex !== -1) {
            wishlist[existingItemIndex].quantity += 1;
          } else {
            wishlist.push(wishlistItem);
          }
          localStorage.setItem(
            appShortName + "wishlist",
            JSON.stringify(wishlist)
          );
          console.log("Item saved to local storage wishlist");
          await fetchWishList(); // Refresh the wishlist
          return true;
        }
      } catch (error) {
        console.error("Error adding item to wishlist:", error);
        return false;
      }
    },
    [navigate, setProductCartDrawerState, handleBagListRef, fetchWishList]
  );

  return { addToWishList };
};

export const useGetWishList = () => {
  const { currentCountry } = useCountryContext();
  const { i18n } = useTranslation();
  const [wishList, setWishList] = useState<any[]>([]);

  const fetchWishList = useCallback(() => {
    if (currentCountry) {
      if (localStorage.getItem(appShortName + "token")) {
        get(
          `wishlist/get/country/${currentCountry.code}/language/${i18n.language}`
        )
          .then((response: any) => {
            if (response && response.status === "OK") {
              setWishList(response.data);
            } else {
              console.error("Unexpected response:", response);
            }
          })
          .catch((error) => {
            console.error("Error fetching wishlist:", error);
          });
      } else {
        const localData = JSON.parse(
          localStorage.getItem(appShortName + "wishlist") || "[]"
        );
        post(
          localData,
          `bags/public/country/${currentCountry.code}/language/${i18n.language}`
        )
          .then((response: any) => {
            if (response && response.status === "OK") {
              setWishList(response.data);
            } else {
              console.error("Unexpected response:", response);
            }
          })
          .catch((error) => {
            console.error("Error syncing wishlist:", error);
          });
      }
    }
  }, []);

  useEffect(() => {
    fetchWishList();
  }, [fetchWishList]);

  return { wishList, fetchWishList }; // Return fetchWishList if needed
};
