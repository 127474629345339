export const Seodata = {
  "pages": [
    {
      "pageUrl": "https://divineempyrean.com",
      "title": "Buy Gold & Diamond Jewellery | Divine Empyrean Online Jewellery  Store",
      "keywords": "Buy Gold & Diamond Jewellery for Women, Men online at on Divine Empyrean Online Store Jaipur Rajasthan India Yellow Gold, Sterling Silver & Diamond and Gemstone Jewellery Sets.",
      "description": "Buy Gold & Diamond Jewellery online at Divine Empyrean Online Store Jaipur India. Sterling Silver, Yellow Gold, Diamond and   Gemstone Jewellery Sets.",
      "canonical": "https://divineempyrean.com"
    },
    {
      "pageUrl": "https://divineempyrean.com/products/new",
      "title": "New Arrivals | Latest Designer Jewellery by Divine Empyrean| Divineempyrean.com",
      "keywords": "",
      "description": "Shop New Arrivals Jewellery in India featuring the latest Sterling Silver, Gold & Diamond  designs and patterns. Jewellery that's new, trending, Sterling Silver Gold & Diamond Jewellery ",
      "canonical": "https://divineempyrean.com/products/new"
    },
    {
      "pageUrl": "https://divineempyrean.com/products/bestsellers",
      "title": "1051 Best Sellers Jwellery Designs by Divine Empyrean| Divineempyrean.com",
      "keywords": "",
      "description": "Discover Top-trending Jewellery Designs! Shop at Divine Empyrean's Best Sellers Collection For Stylish Rings, Necklaces, & Bracelets, Hoops.",
      "canonical": "https://divineempyrean.com/products/bestsellers"
    },
    {
      "pageUrl": "https://divineempyrean.com/products/earrings",
      "title": "Divine Empyrean| Buy Earrings Online at Best Price | Buy Earrings Online ",
      "keywords": "Ear Rings, gold earrings, ear studs designs, pandora earrings, diamond earrings, silver earrings, silver hoop earrings, stud earrings, gold stud earrings, mens earrings, pearl drop earrings, gold earrings for women, diamond stud earrings, designer earrings, rose gold earrings, sapphire earrings, diamond earrings for women, ladies earrings, magnetic earrings, pearl stud earrings, opal earrings, girls earrings",
      "description": "Shop for The Latest Earring Designs Online at Low Prices Our collection includes necklaces, earrings, rings by Divine Empyrean, India.",
      "canonical": "https://divineempyrean.com/products/earrings"
    },
    {
      "pageUrl": "https://divineempyrean.com/products/rings",
      "title": "Divine Empyrean|Buy Rings Design Online Divine Empyrean | Designer Rings Women & Mens | Gold Rings |Daimond Rings.",
      "keywords": "Rings: rings, rings for women, men's rings, gold rings, gold rings for women, gold rings for men, ring design, diamond ring price, engagement rings, promise rings, diamond ring, sapphire ring, lab-grown diamond rings, engagement rings for women, wedding rings, gold diamond ring, pinky rings.",
      "description": "Shop For The Latest Ring Designs Online At Divine Empyrean, India. Choose From Gold, Diamond, Gemstone, And Platinum Rings.",
      "canonical": "https://divineempyrean.com/products/rings"
    },
    {
      "pageUrl": "https://divineempyrean.com/products/necklace",
      "title": "Divine Empyrean| Buy Necklaces For Women Online At Best Price | Buy Necklace  Online .",
      "keywords": "Necklace, gold necklace, pandora necklace, pearl necklace, silver necklace, necklaces for women, white gold necklace, 18k gold necklace, mens necklace, diamond necklace, chain necklace",
      "description": "Shop & Explore our traditional, simple, modern, and latest gold necklace set designs and collections at Divine Empyrean, India. A wide range of necklaces to choose from based on your taste and the occasion. Necklace pieces of several kinds",
      "canonical": "https://divineempyrean.com/products/necklace"
    },
    {
      "pageUrl": "https://divineempyrean.com/products/brecelets",
      "title": "Divine Empyrean| Buy Brecelets  Online At Best Price | Buy Brecelets  for Women & Mens ",
      "keywords": "Bracelets, mens bracelet, gold bracelet, gold bracelets for women, bracelets for women, friendship bracelets, gold bangle, charm bracelets, ankle bracelet, pearl bracelet, mens gold bracelets, baby bracelets, designer bracelets, crystal bracelets, ladies bracelet, rose gold bracelet, couple bracelets, love bracelet, medical bracelets, sapphire bracelet, diamond bracelet women, gold cuff bracelet, best friend bracelets, men's tennis bracelet, custom bracelets, 18k gold bracelet",
      "description": "Bracelets for women by Divine Empyrean, India consist of a vast selection. Light designs like the Cane Geometric Bracelet, set in 18K rose gold & Diamond ",
      "canonical": "https://divineempyrean.com/products/brecelets"
    },
    {
      "pageUrl": "https://divineempyrean.com/products/men's",
      "title": "Buy Diamond Rings for  Men online at Best Price | Best collection for Mens | Best Ring Collection |Divine Empyrean",
      "keywords": "",
      "description": "Diamond mens ring with wide range of latest designed finest gold and diamond jewellery ring collection for men and boys available at affordable prices. Latest Collection Of Diamond Rings For Men",
      "canonical": "https://divineempyrean.com/products/men's"
    },
    {
      pageUrl: "https://divineempyrean.com/products/rings/shop-by-style/engagement-rings",
      title: "Buy Engagement Ring at  Best Price from Divine Empyrean Onlie Store | Diamond Jewellery for Women & Mens | Divine Empyrean",
      keywords: "Engagement rings wedding rings Pinky Rings",
      description: "Buy Engagement Rings online from at divineempyrean Online Store Explore our latest Sterling  Silver Gold Plated and Diamond Jewellery Designs and Collections.",
      canonical: "https://divineempyrean.com/products/rings/shop-by-style/engagement-rings"
    },
    {
      pageUrl: "https://divineempyrean.com/products/rings/shop-by-style/wedding-bands",
      title: "Buy Band Ring  Designs for couples  at Divine Empyrean Onlie Store | Wedding Bnads for Women & Mens | Divine Empyrean",
      keywords: "",
      description: "Shop Band Rings For Weddings, Promises, & Everyday. Choose your favourite wedding ring bands in gold, silver and platinum. A stunning collection of wedding jewellry which includes artistically crafted wedding band rings.",
      canonical: "https://divineempyrean.com/products/rings/shop-by-style/wedding-bands"
    },
    {
      pageUrl: "https://divineempyrean.com/products/rings/shop-by-style/stacking-bands",
      title: "Shop Stacking at Best Price from Divine Empyrean online store | Best Ring Collection |Divine Empyrean",
      keywords: "",
      description: "Get Latest Stacking Bands Designs, Jewellery. Now You Can  Try Best Stackable Rings at Divine Empyrean Online Store Home Perfect for wedding or anniversary rings. Multiple rings stacked rings on one finger. Some people prefer to stack multiple rings on one finger, that is more than one. · Stacked rings on different fingers. Create your unique ring stack with Moon Magic stacking rings. Worldwide delivery & free US shipping.",
      canonical: "https://divineempyrean.com/products/rings/shop-by-style/stacking-bands"
    },
    {
      pageUrl: "https://divineempyrean.com/products/rings/shop-by-style/engagement-rings",
      title: "Buy Engagement Ring at  Best Price from Divine Empyrean Onlie Store | Diamond Jewellery for Women & Mens | Divine Empyrean",
      keywords: "",
      description: "Buy Engagement Rings online from at divineempyrean Online Store Explore our latest Sterling  Silver Gold Plated and Diamond Jewellery Designs and Collections.",
      canonical: "https://divineempyrean.com/products/rings/shop-by-style/engagement-rings"
    }
  ]
}
