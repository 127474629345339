import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import { API_URL } from "../constants/ApiConstants";
import { get } from "../layout/Api/Api";
import Loader from "../layout/Loader/Loader";
import { useParams } from "react-router-dom";
// import { jewelryDataType } from "../public/home/HomeExploreCards/JewelryCardsContainer";

const Jewelry: React.FC = () => {
  const [catalogDetails, setCatalogDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState<boolean[]>([]);

  const { id } = useParams();

  const getDetailById = async (id: string) => {
    setLoading(true);
    try {
      const response = await get(`catalog/details/${id}`);
      if (response?.status === "OK") {
        setCatalogDetails(response.data);
        setImageLoading(
          new Array(response.data.catalogDetail.length).fill(true)
        ); // Initialize loading state for images
      }
    } catch (error) {
      console.error("Failed to fetch catalog details:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getDetailById(id);
    }
  }, [id]);

  const handleImageLoad = (index: number) => {
    setImageLoading((prevState) => {
      const newLoadingState = [...prevState];
      newLoadingState[index] = false; // Mark the image at `index` as loaded
      return newLoadingState;
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box position="relative">
      <Box>
        {catalogDetails && (
          <Box sx={{ overflow: "hidden" }}>
            <Grid container>
              <Grid item xs={12} sx={{background:"black",color:"var(--softPink-color)"}}>
                <Box
                  sx={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {imageLoading[0] && (
                    <CircularProgress
                     
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color:"var(--softPink-color)"
                      }}
                    />
                  )}
                  <Box
                    component="img"
                    src={`${API_URL}images?url=${encodeURIComponent(
                      catalogDetails.catalogDetail[0].image.replace(/\\/g, "/")
                    )}`}
                    alt="catalog"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      display: imageLoading[0] ? "none" : "block",
                    }}
                    onLoad={() => handleImageLoad(0)}
                    onError={() =>
                      setImageLoading((prev) => {
                        const newLoadingState = [...prev];
                        newLoadingState[0] = false;
                        return newLoadingState;
                      })
                    }
                  />
                  {!imageLoading[0] && (
                    <Typography
                      sx={{
                        position: "absolute",
                        bottom: "10px",
                        color: "white",
                        fontSize: "50px",
                      }}
                    >
                      {catalogDetails.title}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sx={{background:"black",color:"var(--softPink-color)"}}>
                <Typography
                  component="div"
                  sx={{
                    width: "100vw",
                    backgroundColor: "black",
                    color: "var(--softPink-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems:"center",
                  }}
                >
                  <h4
                    style={{
                      width: "70%",
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: 200,
                      padding:"20px"
                    }}
                  >
                    {catalogDetails.catalogDetail[0].description}
                  </h4>
                </Typography>
              </Grid>

              {/*  */}
              <Grid container sx={{background:"black",color:"var(--softPink-color)"}}>
                <Grid item xs={12} md={4} order={{ xs: 2, sm: 2, md: 1 }}>
                  <Box
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      height: {xs:"auto",md:"100vh"},
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 100,
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        color: "var(--softPink-color)",
                      }}
                    >
                      <h5 style={{ width: "80%", position: "sticky" }}>
                        {catalogDetails.catalogDetail[1].description}
                      </h5>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={8} order={{ xs: 1, sm: 1, md: 2 }}>
                  <Box
                    sx={{
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {imageLoading[1] && (
                      <CircularProgress
                       
                        sx={{ position: "absolute",color:"var(--softPink-color)" }}
                      />
                    )}
                    <Box
                      component="img"
                      src={`${API_URL}images?url=${encodeURIComponent(
                        catalogDetails.catalogDetail[1].image.replace(
                          /\\/g,
                          "/"
                        )
                      )}`}
                      sx={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: imageLoading[1] ? "none" : "block",
                      }}
                      onLoad={() => handleImageLoad(1)}
                    />
                  </Box>
                </Grid>
                
              </Grid>
              {/*  */}

              <Grid container sx={{background:"black",color:"var(--softPink-color)"}}>
                <Grid item xs={12} md={4.2}>
                  <Box
                    sx={{
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {imageLoading[2] && (
                      <CircularProgress
                       
                        sx={{ position: "absolute",color:"var(--softPink-color)" }}
                      />
                    )}
                    <Box
                      component="img"
                      src={`${API_URL}images?url=${encodeURIComponent(
                        catalogDetails.catalogDetail[2].image.replace(
                          /\\/g,
                          "/"
                        )
                      )}`}
                      sx={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: imageLoading[2] ? "none" : "block",
                      }}
                      onLoad={() => handleImageLoad(2)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4.2}>
                  <Box
                    sx={{
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {imageLoading[3] && (
                      <CircularProgress
                       
                        sx={{ position: "absolute",color:"var(--softPink-color)" }}
                      />
                    )}
                    <Box
                      component="img"
                      src={`${API_URL}images?url=${encodeURIComponent(
                        catalogDetails.catalogDetail[3].image.replace(
                          /\\/g,
                          "/"
                        )
                      )}`}
                      sx={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: imageLoading[3] ? "none" : "block",
                      }}
                      onLoad={() => handleImageLoad(3)}
                    />
                  </Box>
                </Grid>


                <Grid item xs={12} md={3.6}>
                  <Box
                    sx={{
                      height: {xs:"auto",md:"auto",lg:"100vh"},
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "30px",
                        fontWeight: 100,
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <h5 style={{ width: "80%" }}>
                        {catalogDetails.catalogDetail[2].description}
                      </h5>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              

              <Grid container sx={{background:"black",color:"var(--softPink-color)"}}>
                <Grid item xs={12} md={4} order={{ xs: 2, sm: 2, md: 1 }}>
                  <Box
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      height: {xs:"auto",md:"100vh"},
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 100,
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        color: "var(--softPink-color)",
                        margin: "20px",
                      }}
                    >
                      <h5 style={{ width: "80%", position: "sticky" }}>
                        {catalogDetails.catalogDetail[3].description}
                      </h5>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={8} order={{ xs: 1, sm: 1, md: 2 }}>
                  <Box
                    sx={{
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {imageLoading[4] && (
                      <CircularProgress
                        sx={{ position: "absolute",color:"var(--softPink-color)" }}
                      />
                    )}
                    <Box
                      component="img"
                      src={`${API_URL}images?url=${encodeURIComponent(
                        catalogDetails.catalogDetail[4].image.replace(
                          /\\/g,
                          "/"
                        )
                      )}`}
                      sx={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: imageLoading[4] ? "none" : "block",
                      }}
                      onLoad={() => handleImageLoad(4)}
                    />
                  </Box>
                </Grid>
              </Grid>

            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Jewelry;
