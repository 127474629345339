import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';

i18n.use(initReactI18next).init({
  // Supported languages
  lng: "en", // Initial language
  fallbackLng: "en", // Language to fall back to
  // debug: true, // Enable debug mode
  interpolation: {
    escapeValue: false, // React already safely escapes interpolated strings
  },
  // Load translation files
  resources: {},
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
