// import { API_URL, appShortName } from "../../constants/ApiConstants";

// const getHeader = () => {
//   const tokenData = localStorage.getItem(appShortName + "token")
//     ? localStorage.getItem(appShortName + "token")
//     : null;

//   let headers: any = {
//     "Content-Type": "application/json",
//   };
//   if (tokenData) {
//     headers = {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + tokenData,
//     };
//   }
//   return headers;
// };

// export const post = async (data: any, url: string) => {
//   const headers = getHeader();
//   try {
//     const res = await fetch(`${API_URL}${url}`, {
//       method: "POST",
//       headers: headers,
//       body: JSON.stringify(data),
//     });
//     return await res.json();
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const api_delete = async (url: string) => {
//   const headers = getHeader();
//   try {
//     const res = await fetch(`${API_URL}${url}`, {
//       method: "DELETE",
//       headers: headers,
//     });
//     return await res.json();
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const get = async (url: string) => {
//   const headers = getHeader();
//   try {
//     const res = await fetch(`${API_URL}${url}`, {
//       method: "GET",
//       headers: headers,
//     });
//     return await res.json();
//   } catch (error) {
//     console.log(error);
//   }
// };


// export const put = async (data: any, url: string) => {
//   const headers = getHeader();
//   try {
//     const res = await fetch(`${API_URL}${url}`, {
//       method: "PUT",
//       headers: headers,
//       body: JSON.stringify(data),
//     });
//     return await res.json();
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const patch = async (data: any, url: string) => {
//   const headers = getHeader();
//   try {
//     const res = await fetch(`${API_URL}${url}`, {
//       method: "PATCH",
//       headers: headers,
//       body: data ? JSON.stringify(data) : undefined, 
//     });
//     return await res.json();
//   } catch (error) {
//     console.log(error);
//   }
// };



import axios from 'axios';
import { API_URL, appShortName } from "../../constants/ApiConstants";

const getHeader = () => {
  const tokenData = localStorage.getItem(appShortName + "token") || null;

  let headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (tokenData) {
    headers.Authorization = "Bearer " + tokenData;
  }
  return headers;
};

export const post = async (data: any, url: string) => {
  const headers = getHeader();
  try {
    const res = await axios.post(`${API_URL}${url}`, data, { headers });
    return res.data; // Return the data directly
  } catch (error) {
    console.error("Error in POST:", error);
    throw error; // Re-throw error for further handling if needed
  }
};

export const api_delete = async (url: string) => {
  const headers = getHeader();
  try {
    const res = await axios.delete(`${API_URL}${url}`, { headers });
    return res.data; // Return the data directly
  } catch (error) {
    console.error("Error in DELETE:", error);
    throw error;
  }
};

export const get = async (url: string) => {
  const headers = getHeader();
  try {
    const res = await axios.get(`${API_URL}${url}`, { headers });
    return res.data; // Return the data directly
  } catch (error) {
    console.error("Error in GET:", error);
    throw error;
  }
};

export const put = async (data: any, url: string) => {
  const headers = getHeader();
  try {
    const res = await axios.put(`${API_URL}${url}`, data, { headers });
    return res.data; // Return the data directly
  } catch (error) {
    console.error("Error in PUT:", error);
    throw error;
  }
};

export const patch = async (data: any, url: string) => {
  const headers = getHeader();
  try {
    const res = await axios.patch(`${API_URL}${url}`, data, { headers });
    return res.data; // Return the data directly
  } catch (error) {
    console.error("Error in PATCH:", error);
    throw error;
  }
};

