import {
  Box,
  Toolbar,
  Typography,
  IconButton
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { FC, useState } from "react";
import { headerFooterProps } from "../public/Public";
import LanguageSettingsDialog from "../public/LanguageDialog/LanguageSettingsDialog";

library.add(fab);

const Footer: FC<headerFooterProps> = ({
  countries,
  languages,
  onLanguageChange,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");

  return (
    <Toolbar className="footer_section" sx={{ backgroundColor: "#ece9e0" }}>
      <Box className="footer_inner_section">
        <Box className="membership">

          <Typography
            variant="body2"
            component="a"
            className="link"
            onClick={() => navigate("/terms-and-conditions")}
          >
            {t("footer.T&C")}
          </Typography>
          <Typography
            variant="body2"
            component="a"
            className="link"
            onClick={() => navigate("privacy-policy")}
          >
            {t("footer.Privacy-policy")}
          </Typography>
        </Box>
        <Box className="copyright">
          <Typography variant="body2">{t("footer.copyright")}</Typography>
        </Box>
        <Box className="social_icon">
  <IconButton 
    aria-label="Facebook" 
    size="small" 
    component="a" 
    href="https://www.facebook.com/people/Divineempyrean/61558033318183/" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={["fab", "facebook"]} />
  </IconButton>
  
  <IconButton 
    aria-label="Instagram" 
    size="small" 
    component="a" 
    href="https://www.instagram.com/divineempyrean747/" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={["fab", "instagram"]} />
  </IconButton>
  
  <IconButton 
    aria-label="Pinterest" 
    size="small" 
    component="a" 
    href="https://in.pinterest.com/divineempyrean747/" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={["fab", "pinterest"]} />
  </IconButton>
  
  <IconButton 
    aria-label="Twitter" 
    size="small" 
    component="a" 
    href="https://x.com/DeepakSoni30357" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={["fab", "x-twitter"]} />
  </IconButton>
</Box>

      </Box>
    </Toolbar>
  );
};

export default Footer;
