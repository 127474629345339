import React from "react";
import { Button, Typography, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

interface ExploreHeaderProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExploreHeader: React.FC<ExploreHeaderProps> = ({ open, setOpen }) => {
  const navigate = useNavigate();

  const HeaderStyle = styled(Box)({
    width: "100vw",
    backgroundColor: "#2C2C2B",
    height: "80px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    zIndex: "10",
  });

  return (
    <HeaderStyle component="nav">
      <Typography
        variant="h6"
        color="initial"
        sx={{ paddingLeft: "50px", color: "white" }}
      >
        <Button
          onClick={() => setOpen(!open)}
          sx={{ color: "white" }}
          endIcon={open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        >
          Divine Empyrean Eternal Time -
        </Button>
      </Typography>
      <Typography
        variant="h5"
        color="initial"
        sx={{ paddingRight: "30px", color: "white" }}
      >
        <CancelIcon
          onClick={() => {
            setOpen(false);
            navigate("/");
          }}
          sx={{
            background: "transparent",
            fontSize: "20px",
            cursor: "pointer",
            zIndex: 10,
          }}
        />
      </Typography>
    </HeaderStyle>
  );
};

export default ExploreHeader;
