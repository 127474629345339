import React from 'react';
import { Helmet } from 'react-helmet-async';

interface HelmetSeoProps {
  title: string;
  description: string;
  keywords: string;
  canonicalUrl?: string;
  robots:string;
}

const HelmetSeo: React.FC<HelmetSeoProps> = ({ title, description, keywords, canonicalUrl,robots }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content={robots}/>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  );
}

export default HelmetSeo;
