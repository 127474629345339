import {
  Box,
  Typography,
  Drawer,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { post } from "./Api/Api";
import "./subHeaderDrawer.scss";
import { useNavigate } from "react-router-dom";
import { IMG_URL } from "../constants/ApiConstants";

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  showSubHeader:boolean;
}


const SearchCard: React.FC<any> = ({data, language,showSubHeader}) => {
  return (
    <Box sx={{ cursor: "pointer" }}>
      <img
        src={
          data?.image_url && IMG_URL + data?.image_url ||
          "/images/demo/new_arrival/0-LABGROWNDIAMONDSBEZEL-BabyBezelScatteredRing-14K-Angled_329.jpg"
        }
        alt="search card img"
        style={{ width: "100%" }}
      />
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {data?.content?.[language]?.sku_title}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: "400" }}>
        {data?.categoryName}
      </Typography>
    </Box>
  );
};

const SearchDrawer: React.FC<DrawerProps> = ({ open, onClose,showSubHeader }) => {
  const { t, i18n } = useTranslation();
  // const [variant, setVariant] = useState<TextFieldVariants>("standard");
  const [isFocused, setIsFocused] = useState(false);
  const [searchField, setSearchField] = useState<string>("");
  const [porductList, setProductList] = useState<any[]>([]);


  const navigate = useNavigate();
  const pagination = {
    limit: 10,
    page: 1,
  };
  const [drawerTop, setDrawerTop] = useState(105);
  useEffect(() => {
    const handleScroll = () => {
      setDrawerTop(105 - window.scrollY);
    };

    const handleResize = () => {
      // Handle resize logic here
      if(window.innerWidth <= 600){
        setDrawerTop(89 - window.scrollY);
        if(window.innerWidth <= 511){
          setDrawerTop(97 - window.scrollY);}
        if(window.innerWidth <= 320){
          setDrawerTop(97 - window.scrollY);}
      }else
      setDrawerTop(105 - window.scrollY);


    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSearch = (value: string) => {
    // setSearchField(value);
    console.log("search", value);
    post(
      {
        keyword: value,
      },
      `products/search?limit=${pagination.limit}&page=${pagination.page}`
    ).then((response: any) => {
      if (response && response.status) {
        console.log("response", response.data.data);
        setProductList(response.data.data);
      }
    });
  };
  const topSearch = [
    "Heirloom",
    "Pearl",
    "Hoop Earrings",
    "Charm",
    "Heart",
    "Emerald",
    "Letter",
  ];

  return (
    <Drawer
      anchor="top"
      open={open}
      onClose={onClose}
      className="searchDrawer"
      hideBackdrop
      // ModalProps={{
      //   BackdropProps: {
      //     invisible: true, // Make the backdrop invisible
      //   },
      // }}
      PaperProps={{
        elevation: 6, // Remove the shadow
        style: {
          height: "450px",
          top: drawerTop < 0 ? (showSubHeader ? "100px":"60px") : drawerTop,
          backgroundColor: "var(--light-color)",
          padding: "32px 32px 16px",
          boxSizing: "border-box",
        },
      }}
      SlideProps={{
        appear: false,
      }}
    >
      {/* Drawer content goes here */}
      <Box
        style={{
          width: "45%",
          height: "100%",
          boxSizing: "border-box",
          margin: "0 auto",
          
        }}

        sx={{
          '@media (max-width: 992px)': {
            width: '75% !important', // Example change for smaller screens
          },
          '@media (max-width: 600px)': {
            width: '90% !important', // Example change for smaller screens
          },
        }}
      >
        <Box className="serch_cell">
          <TextField
            id="standard-basic"
            placeholder={t("SearchDrawer.input.placeholder")}
            helperText={t("SearchDrawer.input.helperText")}
            // variant={variant}
            variant="outlined"
            fullWidth
            value={searchField}
            sx={{ height: 45 }}
            FormHelperTextProps={{
              style: {
                fontFamily: "var(--font-family2)",
              },
            }}
            InputProps={{
              endAdornment : (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                fontFamily: "var(--font-family2)",
                height: "100%",
              },
              classes: {
                notchedOutline: isFocused ? "outlined-focused" : "outlined",
              },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchField(event.target.value);
              if (!event.target.value || event.target.value === "") {
                handleSearch(event.target.value);
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const target = event.target as HTMLInputElement;
                handleSearch(target.value);
              }
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </Box>

        <Box className="productList">
          {porductList.length === 0 && (
            <Box>
              <Typography
                variant="h6"
                mb={1}
                sx={{ textTransform: "capitalize" }}
              >
                {t("Home.banner.top.search")}
              </Typography>
              <List sx={{ width: "100%", maxWidth: 360 }}>
                {topSearch.map((value) => (
                  <ListItem
                    key={value}
                    disableGutters
                    className="topSearch-list"
                    onClick={() => {
                      setSearchField(value);
                      handleSearch(value);
                    }}
                    sx={{cursor: 'pointer'}}
                  >
                    <ListItemText primary={value} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {porductList.length !== 0 && (
            <Box>
              <Typography
                variant="body2"
                mb={1}
                sx={{ textTransform: "uppercase" }}
              >
                {porductList.length}&nbsp;products
              </Typography>
              <Grid container spacing={1}>
                {porductList.map((value) => (
                  <Grid
                    item
                    xs={6} sm={4} md={3}
                    key={value.id}
                    onClick={() => {
                      onClose();
                      navigate(
                        // `/products/${value?.categoryKey}/${value?.styleKey}/${value?.subCategoryKey}`
                        // `/product/details/${value?.id}`
                        //  `/product/${porductList.abbrevation}`
                         `/product/${value.abbrevation}`

                      );
                    }}
                  >
                    <SearchCard data={value} language={i18n.language}/>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default SearchDrawer;
// export default SearchDrawer;
