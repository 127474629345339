import React, { lazy, Suspense, useEffect, useRef, useState } from "react";



import { get } from "../layout/Api/Api";
import { Outlet } from "react-router-dom";
import { jewelryDataType } from "../public/home/HomeExploreCards/JewelryCardsContainer";

import ExploreHeader from "./ExploreHeader";
import  JewelryDrawer from"./JewelryDrawer";





export default function JewelryBase() {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [data, setData] = useState<jewelryDataType[]>([]);
  const isMount = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get("catalog/getAll");
        if (response?.status === "OK") {
          setData(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch catalog data:", error);
      }
    };
    if (!isMount.current) {
      isMount.current = true;
      fetchData();
    }
  }, []);

  return (
    <>
        <ExploreHeader open={drawerOpen} setOpen={setDrawerOpen} />
        <JewelryDrawer open={drawerOpen} handleClose={() => setDrawerOpen(false)} data={data} />
        <Outlet />
    </>
  );
}
