import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Toolbar,
  Dialog,
  Rating,
  Avatar,
  Chip,
  Stack,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../../layout/Api/Api";
import "./ProductDetails.scss";
import { IMG_URL, appShortName } from "../../../constants/ApiConstants";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-multi-carousel";
import { NewArrivalCards, responsive } from "../../home/NewArrival/NewArrival";
import { useCountryContext } from "../../Public";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useTranslation } from "react-i18next";
import { useCartContext } from "../../context/CartContext";
import { Favorite } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";



export interface sizeGuideProps {
  open: boolean;
  onClose: () => void;
}

function SizeGuideDialog(props: sizeGuideProps) {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <img
        src="/images/demo/size_guide.png"
        alt="ring size guide"
        height="100%"
        width="100%"
      />
    </Dialog>
  );
}

export default function ProductDetail() {
  const navigate = useNavigate();
  const { abbreviation } = useParams();
  const [productDetails, setProductDetials] = useState<any>();
  const [quantity, setQuantity] = useState<number>(1);
  const [productSize, setProductSize] = useState<string>("");
  const [contents, setContents] = useState<any[]>([]);
  const [openContentDrawer, setOpenContentDrawer] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState("");
  const [textColor, setTextColor] = useState("green");
  const [heartState, setHeartState] = useState(false);


  const [currentContentForDrawer, setCurrentContentForDrawer] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [productList, setProductList] = useState<any[]>([]);

  const handleSizeChange = (event: SelectChangeEvent) => {
    console.log("size value", event.target.value);
    setProductSize(event.target.value as string);
  };

  const { currentCountry } = useCountryContext();
  const { setProductCartDrawerState, handleBagListRef } = useCartContext();
  const pagination = {
    limit: 20,
    page: 1,
  };
  const [windowWidth, setWindowWidth] = useState(0);
  const { i18n } = useTranslation();
  const [sizeGuideDialogState, setSizeGuideDialogState] =
    useState<boolean>(false);

  const reviewSectionRef = useRef<HTMLDivElement | null>(null);

  const handleSearch = () => {
    if (currentCountry) {
      setLoading(true);
      try {
        post(
          {
            keyword: "",
          },
          `products/search?page=${pagination.page}&limit=${pagination.limit}&country=${currentCountry.code}&language=${i18n.language}`
        ).then((response: any) => {
          if (response && response.status === "OK") {
            setProductList(response.data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } catch {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // if (isMounted.current) {
    handleSearch();
    // } else {
    //   isMounted.current = true;
    // }
  }, [currentCountry]);

  useEffect(() => {
    getProductDetails();
  }, [abbreviation, currentCountry]);

  const getProductDetails = async () => {
    if (currentCountry) {
      const response = await get(
        `products/get/${abbreviation}?country=${currentCountry.code}&language=${i18n.language}`
      );

      if (response && response.status === "OK") {
        setProductDetials(response.data);
        setHeartState(response.data.inWishlist);
        setProductSize(response.data.specification?.us.size_availability?.[0]);
        getReview(response.data.id);
      }
    }
  };
  useEffect(() => {
    getAllProductContent();
    // window.scrollTo(0, 0);
  }, [currentCountry]);

  const getAllProductContent = () => {
    try {
      get("product/contents/getAll").then((response: any) => {
        if (response && response.status === "OK") {
          setContents(response.data);
        }
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const addToBag = () => {
    if (productDetails && productDetails.id) {
      if (localStorage.getItem(appShortName + "user")) {
        const cartItem = {
          id: productDetails.id,
          quantity: quantity,
          size: productSize,
          color: selectedColor,
        };
        post(cartItem, "bags/add").then((response: any) => {
          if (response && response.status === "OK") {
            if (handleBagListRef.current) {
              handleBagListRef.current?.getBagListWithBagTab();
            }
            setProductCartDrawerState(true);
          }
        });
      } else {
        let cartProduct: any[] = JSON.parse(
          localStorage.getItem(appShortName + "cart") || "[]"
        );
        const existingProductIndex = cartProduct.findIndex(
          (item) => item.id === productDetails.id
        );
        if (existingProductIndex !== -1) {
          cartProduct[existingProductIndex].quantity = quantity;
        } else {
          cartProduct.push({
            id: productDetails.id,
            quantity: quantity,
            size: productSize || "10",
            color: selectedColor,
          });
        }
        localStorage.setItem(
          appShortName + "cart",
          JSON.stringify(cartProduct)
        );
        if (handleBagListRef.current) {
          handleBagListRef.current?.getBagListWithBagTab();
        }
        setProductCartDrawerState(true);
      }
    }
  };

  const addToWishList = async () => {
    if (productDetails && productDetails.id) {
      try {
        const wishlistItem = {
          id: productDetails.id,
          quantity: quantity,
          size: productSize || "10",
          color: selectedColor,
        };
        const user = JSON.parse(localStorage.getItem("divineuser") || "null");

        if (user && user.id) {
          // User is logged in, send request to add item to wishlist
          const response = await post(
            wishlistItem,
            `wishlist/add/${productDetails.id}`
          );
          if (response && response.status === "OK") {
            console.log("Item successfully added to wishlist on the server");
            setHeartState(!heartState);
            // Fetch updated wishlist and open the drawer
            handleBagListRef.current?.getWishListWithWishListTab();
            setProductCartDrawerState(true);
          } else {
            console.error("Failed to add item to wishlist:", response);
          }
        } else {
          // User is not logged in, redirect to signup page
          navigate("/signup");
        }
      } catch (error) {
        console.error("Error adding item to wishlist:", error);
      }
    } else {
      console.error("Product details are missing or invalid");
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const [productReviews, setProductReviews] = useState<Array<any>>([]);

  const getReview = (productId: any) => {
    get(`products/reviews/product/${productId}`)
      .then((res) => {
        if (res && res.status === "OK" && res.data && res.data.length > 0) {
          setProductReviews(res.data); // Set the array of reviews
        } else {
          setProductReviews([]); // Set empty array if no reviews found
          console.log("No reviews found or API did not respond correctly");
        }
      })
      .catch((err) => console.log("Error fetching reviews:", err));
  };

  const stockQuantity = productDetails?.specification?.us?.stock_quantity;
  const stockDisplay =
    stockQuantity === 0
      ? "out of stock"
      : stockQuantity < 5
        ? `Low in Stock, Only → ${stockQuantity} left`
        : stockQuantity <= 10
          ? "Only Few Left"
          : stockQuantity;

  const endTimeIs = productDetails?.specification?.us?.saleEndTime;
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const timeDifference = endTimeIs - now;

      if (timeDifference > 0) {
        // Calculate remaining days
        const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));


        if (daysLeft > 7) {
          // If more than 7 days, show "Nothing"
          setRemainingTime("");
          setTextColor("transparent"); // You can adjust this color as needed
        } else if (daysLeft > 3) {
          // If more than 3 days but less than or equal to 7 days
          setRemainingTime(`Sale ends in: ${daysLeft} days`);
          setTextColor("green");
        } else if (daysLeft < 3 && daysLeft >= 0) {
          // If less than 3 days
          const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
          const hours = totalHours; // Total hours remaining
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000); // Remaining seconds

          setTextColor("red");
          setRemainingTime(
            `Sale ends in: ${hours}h:${minutes < 10 ? `0${minutes}` : minutes}m:${seconds < 10 ? `0${seconds}` : seconds}s`
          );
        }
      }
    }, 1000); // Update every 1 second

    return () => clearInterval(intervalId);
  }, [endTimeIs]);

  const colors1 = ['silver', 'gold']
  const [selectedColor, setSelectedColor] = useState<string>("silver");




  return ( //sx={{ margin: { xs: "100px 10px", lg: "0 40px" } }}
    <Box sx={{ minHeight: "80vh" }}>
      {productDetails && (
        <>
          <Box>
            <Grid
              container
              className="details_section"
              sx={{ boxSizing: "border-box" }}
            >
              <Grid
                item
                className="details_cell_1"
                sm={12}
                md={8}
                lg={8}
                xl={8}
                sx={{ p: "12px" }}
              >
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {productDetails.largeImageUrl?.length > 0 &&
                    productDetails.largeImageUrl.map(
                      (largeImgUrl: string, index: number) => (
                        <Grid item key={`img-${index}`} xs={6} sm={5} lg={6}>
                          <Zoom
                            zoomImg={{
                              src: IMG_URL + productDetails.image_url[index],
                              alt: `Zoomed Image ${index + 1}`,
                              style: { width: "500%", height: "500%" },
                            }}
                          >
                            <img
                              alt={productDetails.name}
                              src={IMG_URL + largeImgUrl}
                              style={{ width: "100%", cursor: "zoom-in" }}
                            />
                          </Zoom>
                        </Grid>
                      )
                    )}
                </Grid>
              </Grid>

              <Grid
                item
                className="details_cell_2"
                sm={12}
                md={4}
                sx={{ p: "12px" }}
              >
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: "5px",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label=""
                      className="heart_icon"
                      size="small"
                      onClick={() => {
                        addToWishList().then((response: any) => {
                          if (response) {
                            setHeartState(!productDetails.inWishlist);
                            productDetails.inWishlist =
                              !productDetails.inWishlist;
                          }
                        });
                      }}
                    >
                      {heartState ? (
                        <Favorite sx={{ color: "#ff0000" }} />
                      ) : (
                        <FavoriteBorderIcon />
                      )}
                    </IconButton>
                  </Paper>
                </Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.5rem", color: "black" }}
                >
                  {productDetails.content?.[i18n.language]?.sku_title}
                </Typography>
                <Box>
                  <Typography
                    component="p"
                    sx={{
                      color: "red",
                      fontSize: { xs: "12px", sm: "1rem" },
                    }}
                  >
                    {stockDisplay}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {productDetails?.specification?.us?.sales_price &&
                    productDetails?.specification?.us?.sales_price !==
                    productDetails?.specification?.us?.base_price ? (
                    <Box
                      sx={{
                        display: "flex",
                        // background: "#f4f4f461",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "1.5rem",
                          paddingRight: "5px",
                          color: "#0f743b", //-----
                          fontWeight: 600,
                        }}
                      >
                        {currentCountry?.currencyIcon}
                        {productDetails?.specification?.us?.sales_price.toFixed(
                          2
                        )}
                      </Typography>

                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "13px", sm: "15px" },
                          textDecoration: "line-through",
                          color: "#b5b5b5",
                          fontWeight: "400",
                          fontStyle: "italic",
                        }}
                      >
                        {currentCountry?.currencyIcon}
                        {productDetails?.specification?.us?.base_price.toFixed(
                          2
                        )}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          width: { xs: "80px", sm: "100px" },
                          fontWeight: "bolder",
                          color: "gray",
                        }}
                      >
                        {`(${productDetails?.specification?.us?.discount ?? "0"
                          }% Off)`}
                      </Typography>

                      <Chip
                        sx={{
                          height: "25px",
                          width: { xs: "80px", sm: "100px" },
                          backgroundColor: "#a0e193",
                          fontSize: { xs: "8px", sm: "10px" },
                        }}
                        label="FREE shipping"
                      />
                    </Box>
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{
                        paddingRight: "25px",
                        fontSize: { xs: "13px", sm: "18px" },
                        color: "var(--Price-color)",
                      }}
                    >
                      {currentCountry?.currencyIcon}{productDetails?.specification?.us?.base_price.toFixed(2)}

                    </Typography>
                  )}

                  <Typography
                    component="p"
                    sx={{
                      color: textColor, // Use the dynamic text color
                      fontSize: "1.2rem",
                    }}
                  >
                    {`${remainingTime}`}
                  </Typography>
                </Box>

                <Typography
                  variant="body2"
                  sx={{ fontSize: "1rem", letterSpacing: "1px" }}
                >
                  Local taxes included (where applicable)
                </Typography>

                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: "500" }}>
                    divineempyren
                  </Typography>
                  <img height={15} src="/img/rating2.png" alt="star-img" />
                  {productDetails && Number(productDetails.rating) > 0 ? (
                    <Rating
                      value={Number(productDetails?.rating) || 0}
                      precision={0.1} // Allows for precision like 3.375
                      readOnly
                      sx={{ "& .MuiRating-icon": { fontSize: "15px" } }}
                    />
                  ) : null}
                </Box>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <DoneIcon />
                  <Typography variant="body2" color="initial">
                    Returns & exchanges accepted
                  </Typography>
                </Box>

                <Divider className="divider-light" />

                <Grid container spacing={1}>
                  <Grid item xs={5} lg={5}>
                    <Box>
                      <Typography variant="body2" mb={1}>
                        Material:&nbsp;
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          {productDetails?.main_material}
                        </Typography>
                      </Typography>
                      <img
                        src={IMG_URL + productDetails?.smallImageUrl?.[0]}
                        alt="1st product"
                        style={{ width: "30%" }}
                      />

                      <Box>
                        <Typography variant="body2" mb={1}>
                          color:&nbsp;
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{ fontWeight: "bold" }}
                          >
                            {selectedColor}
                          </Typography>
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          {colors1.map((color: string, index: number) => {
                            return (
                              <Box
                                key={index}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50%",
                                  background: color.toLowerCase(), // Assuming color names match CSS color names
                                  outline: "1px solid black",
                                  cursor: "pointer",
                                }}
                                onClick={() => setSelectedColor(color)}
                              ></Box>
                            );
                          })}
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={7} lg={7}>
                    <Box>
                      <Box className="size_section" mb={2}>
                        <Typography variant="body1">Size</Typography>
                        <Typography
                          variant="body1"
                          className="link"
                          onClick={() => setSizeGuideDialogState(true)}
                        >
                          Size guide
                        </Typography>
                      </Box>
                      <Box>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Size
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={productSize}
                            label="Size"
                            onChange={handleSizeChange}
                            required
                          >
                            {productDetails.specification?.us
                              ?.size_availability &&
                              productDetails.specification?.us?.size_availability.map(
                                (item: any) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/*  */}
                <Divider className="divider-light" />
                {/*  */}
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "uppercase", fontWeight: "bold" }}
                    mb={2}
                  >
                    quantity
                  </Typography>

                  <Box
                    className="quantity_section"
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                    mb={2}
                  >
                    <IconButton
                      aria-label="pre"
                      sx={{
                        height: "30px",
                        width: "30px",
                        border: "1px solid black",
                      }}
                      onClick={() => setQuantity(Math.max(quantity - 1, 1))}
                    >
                      <RemoveIcon />
                    </IconButton>

                    <Typography variant="h6">{quantity}</Typography>
                    <IconButton
                      aria-label="next"
                      sx={{
                        height: "30px",
                        width: "30px",
                        border: "1px solid black",
                      }}
                      onClick={() =>
                        setQuantity(
                          Math.min(
                            quantity + 1,
                            productDetails.specification?.us?.stock_quantity
                          )
                        )
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                  <Grid container spacing={0.5}>
                    <Grid item xs={10}>
                      <Button
                        variant="contained"
                        className="addToCart_btn"
                        onClick={addToBag}
                        fullWidth
                        sx={{ borderRadius: 0 }}
                      >
                        add to bag
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        className="addToCart_btn"
                        onClick={addToWishList}
                        fullWidth
                        sx={{ borderRadius: 0 }}
                      // disabled={!localStorage.getItem(appShortName + "user")}
                      >
                        {heartState ? (
                          <Favorite sx={{ color: "#ff0000" }} />
                        ) : (
                          <FavoriteBorderIcon />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* <Button
                    variant="contained"
                    onClick={() =>
                      navigate("/ticket", { state: { openTicketDialog: true } })
                    }
                    fullWidth
                    sx={{
                      borderRadius: 0,
                      backgroundColor: "var(--dark-color)",
                      color: "var(--light-color)",
                      height: "45px",
                      margin: "5px 0",
                      "&:hover": {
                        backgroundColor: "var(--dark-color)",
                        color: "var(--light-color)",
                        opacity: 0.8,
                      },
                    }}
                  >
                    create ticket
                  </Button> */}
                </Box>
                <Divider className="divider-light" />
                <Box>
                  <List>
                    {contents.length > 0 &&
                      contents.map((content) => (
                        <ListItem
                          disablePadding
                          key={content.id}
                          secondaryAction={<NavigateNextIcon />}
                          onClick={() => {
                            setCurrentContentForDrawer(content);
                            setOpenContentDrawer(true);
                          }}
                        >
                          <ListItemButton className="accordion">
                            <ListItemIcon>
                              <img
                                src={IMG_URL + content?.icon}
                                alt="img"
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  borderRadius: "50%",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                ".css-10hburv-MuiTypography-root": {
                                  width: "90%",
                                },
                              }}
                              primary={content.title}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box p={1}>
            <Typography variant="body2" sx={{ textTransform: "uppercase" }}>
              {productDetails.content?.[i18n.language]?.sku_title}
            </Typography>
            <Box
              dangerouslySetInnerHTML={{
                __html:
                  productDetails.content?.[i18n.language]
                    ?.sku_short_description,
              }}
              p={1}
            />
          </Box>
          <Grid container p={1}>
            <Grid
              item
              sm={6}
              xs={12}
              sx={{ p: "16px" }}
              className="matels_diamond_section"
            >
              <Box my={2}>
                <Typography
                  variant="body2"
                  sx={{
                    textTransform: "uppercase",
                    borderBottom: "1px solid #ccc",
                    fontWeight: "bold",
                  }}
                  mb={1}
                >
                  Metals
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    textTransform: "uppercase",
                    borderBottom: "1px solid #ccc",
                    width: "max-content",
                  }}
                  mb={1}
                >
                  {productDetails.main_material}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  mb={1}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Contrast
                  </Typography>
                  <Typography variant="body2">
                    {productDetails.size_dimensions?.contrast || "750/000"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  mb={1}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Weight
                  </Typography>
                  <Typography variant="body2">
                    {productDetails.size_dimensions?.weight || "1.974g"}
                  </Typography>
                </Box>
              </Box>

              <Box my={6}>
                <Typography
                  variant="body2"
                  sx={{
                    textTransform: "uppercase",
                    borderBottom: "1px solid #ccc",
                    fontWeight: "bold",
                  }}
                  mb={1}
                >
                  DIAMONDS
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    textTransform: "capitalize",
                    borderBottom: "1px solid #ccc",
                    width: "max-content",
                  }}
                  mb={1}
                >
                  BRILLIANT-CUT DIAMOND, H/SI
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  mb={1}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Weight
                  </Typography>
                  <Typography variant="body2">
                    {productDetails.size_dimensions?.weight || "0.21 ct"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              sx={{ pl: "16px" }}
              className="General_section"
            >
              <Box my={2}>
                <Typography
                  variant="body2"
                  sx={{
                    textTransform: "uppercase",
                    borderBottom: "1px solid #ccc",
                    fontWeight: "bold",
                  }}
                  mb={1}
                >
                  GENERAL INFORMATION
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  mb={1}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Collection
                  </Typography>
                  <Typography variant="body2">
                    {productDetails.size_dimensions?.weight || "Les Classiques"}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{ textDecoration: "underline" }}
                  mb={1}
                >
                  Jewelry quality certificates
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textDecoration: "underline" }}
                  mb={1}
                >
                  Jewelry care
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textDecoration: "underline" }}
                  mb={1}
                >
                  After-Sales Service
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container my={0} p={1}>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              sx={{ pr: "12px" }}
              className="freshwaterimg"
            >
              <img
                src={IMG_URL + productDetails.image_url?.[1]}
                alt="img"
                style={{
                  width: "100%",
                  aspectRatio: "3/2",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12} className="freshwater_section">
              <Box
                ref={reviewSectionRef}
                sx={{
                  height: "100%",
                  width: "100%",
                  background: "#c5344d0d",
                  color: "white",
                  maxHeight: "450px",
                  overflowY: "auto",
                  borderRadius: "5px",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },

                  "&:hover": {
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": {
                      display: "block",
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "var(--softPink-color)",
                      borderRadius: "4px",
                    },
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "black", fontSize: "1.5rem", p: 1 }}
                >
                  Review's
                </Typography>
                <Divider variant="middle" />

                <Grid container>
                  {productReviews.length > 0 ? (
                    productReviews.map((review, index) => (
                      <Grid container spacing={1} key={index} sx={{ m: 1 }}>
                        <Grid
                          item
                          m={1}
                          sm={2}
                          md={2}
                          lg={1}
                          sx={{
                            display: { xs: "none", md: "none", lg: "block" },
                          }}
                        >
                          <Avatar
                            alt={review.name}
                            src={
                              review.profileImage ||
                              "/static/images/avatar/1.jpg"
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={10}
                          sx={{
                            width: "100%",
                            color: "black",
                            background: "#ffffff9c",
                            p: 1,
                            borderRadius: "5px",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <Typography
                              variant="body2"
                              sx={{ borderBottom: "1px solid gray" }}
                            >
                              {review.name}
                            </Typography>
                            <Typography variant="body2">
                              On{" "}
                              {new Date(review.createdAt).toLocaleDateString()}
                            </Typography>
                          </Box>
                          <Box sx={{ padding: "0 20px", mt: 1 }}>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "left" }}
                            >
                              {review.review}
                            </Typography>
                            <Box>
                              <Rating
                                sx={{
                                  "& .MuiRating-icon": { fontSize: "15px" },
                                }}
                                value={Number(review.rating)}
                                precision={0.5}
                                readOnly
                              />
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="body2">
                              Purchased item:
                            </Typography>
                            <Box
                              sx={{
                                m: 1,
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                height={50}
                                src={
                                  review.profileImage ||
                                  IMG_URL + productDetails?.image_url?.[0]
                                }
                                alt="product-img"
                                style={{ borderRadius: "5px" }}
                              />
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ borderBottom: "1px solid black" }}
                                >
                                  {
                                    productDetails.content?.[i18n.language]
                                      ?.sku_title
                                  }
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ color: "black", fontSize: "1.2rem" }}
                    >
                      There are no reviews for this product at the moment...
                    </Typography>
                  )}
                </Grid>
              </Box>

              {/* <Box
                    dangerouslySetInnerHTML={{
                      __html:
                        productDetails.content?.[i18n.language]
                          ?.sku_long_description,
                    }}
                  /> */}
            </Grid>
          </Grid>

          <Box my="30px">
            <Carousel
              responsive={responsive}
              infinite

            >
              {productList.length > 0 &&
                productList.map((item, index) => (
                  <NewArrivalCards key={index} {...item} />
                ))}
            </Carousel>
          </Box>
        </>
      )}

      <ContentDrawer
        open={openContentDrawer}
        onClose={() => setOpenContentDrawer(false)}
        data={currentContentForDrawer}
      />

      {/* <ProductCart
        open={productCartDrawerState}
        onClose={() => {
          setProductCartDrawerState(false);
        }}
        handleBagListRef={handleBagListRef}
      /> */}

      <SizeGuideDialog
        open={sizeGuideDialogState}
        onClose={() => setSizeGuideDialogState(false)}
      />
    </Box>
  );
}

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

const ContentDrawer: React.FC<DrawerProps> = ({ open, onClose, data }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      // className="storeAndServices"
      PaperProps={{
        elevation: 1,
        style: {
          width: "430px",
          backgroundColor: "var(--merino-color)",
          boxSizing: "border-box",
        },
        sx: {
          "@media (max-width: 600px)": {
            width: "70% !important", // Example change for smaller screens
          },
          "@media (max-width: 450px)": {
            width: "100% !important", // Example change for smaller screens
          },
        },
      }}
    >
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          padding: "20px",
          lineHeight: "1.6",
        }}
      >
        <Toolbar
          sx={{ justifyContent: "space-between", padding: "0px !important" }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "var(--dark-color)" }}
          >
            {data.title}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderColor: "var(--dark-color)" }} />
        <Box dangerouslySetInnerHTML={{ __html: data.content }} />
      </Box>
    </Drawer>
  );
};
