import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import StorefrontIcon from "@mui/icons-material/Storefront";
import "./subHeaderDrawer.scss";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appShortName } from "../constants/ApiConstants";
import MessageIcon from "@mui/icons-material/Message";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
interface DrawerProps {
  open: boolean;
  onClose: () => void;
  showSubHeader:boolean
}
interface MainDrawerProps {
  open: boolean;
  data: any;
  onClose: () => void;
  showSubHeader:boolean;
}

export const StoreAndServices: React.FC<DrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [drawerTop, setDrawerTop] = useState(40);
  useEffect(() => {
    const handleScroll = () => {
      setDrawerTop(40 - window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="storeAndServices"
      // hideBackdrop
      ModalProps={{
        container: document.querySelector("#drawer-container"), // Specify the container where the drawer will be rendered
        BackdropProps: {
          invisible: true, // Make the backdrop invisible
        },
      }}
      PaperProps={{
        elevation: 4, // Remove the shadow
        style: {
          height: "460px",
          width: "430px",
          // top: drawerTop < 0 ? 0 : drawerTop,
          top:'40px',
          backgroundColor: "var(--merino-color)",
          padding: "32px 32px 16px",
        },
        sx: {
          "@media (max-width: 850px)": {
            width: "50% !important", // Example change for smaller screens
            top:"40px",
          },
          "@media (max-width: 550px)": {
            width: "80% !important", // Example change for smaller screens
            top:"40px"
          },
        },
      }}
      SlideProps={{
        appear: false,
      }}
    >
      {/* Drawer content goes here */}
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
        onMouseLeave={onClose}
      >
        <Typography variant="h6" component="a" className="seeAllStore">
          <StorefrontIcon sx={{ fontSize: "larger" }} />
          {t("storeAndServices.heading")}
        </Typography>
        <Divider className="divider" />
        <Typography
          variant="body1"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        >
          {t("storeAndServices.services")}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                width="100%"
                // image="/images/demo/Peircing_Studio.webp"
                image="/img/store&service/store1.png"
              />
              <CardContent>
                <Typography gutterBottom variant="body1" component="div">
                  {t("storeAndServices.card1.heading")}
                </Typography>
                <Typography variant="body2">
                  {t("storeAndServices.card1.text")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                width="100%"
                // image="/images/demo/Styling_Appointment.webp"
                image="/img/store&service/store2.png"
              />
              <CardContent>
                <Typography gutterBottom variant="body1" component="div">
                  {t("storeAndServices.card2.heading")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    display: "-webkit-box", // Flexbox for truncating text
                    overflow: "hidden", // Hides overflowing text
                    WebkitBoxOrient: "vertical", // Required for line clamping
                    WebkitLineClamp: 2, // Limits to 2 lines
                    lineClamp: 2, // For non-WebKit browsers (fallback)
                    textOverflow: "ellipsis",
                  }}
                >
                  {t("storeAndServices.card2.text")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export const HelpDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [drawerTop, setDrawerTop] = useState(41);
  useEffect(() => {
    const handleScroll = () => {
      setDrawerTop(40 - window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="storeAndServices"
      // hideBackdrop
      ModalProps={{
        container: document.querySelector("#drawer-container"), // Specify the container where the drawer will be rendered
        BackdropProps: {
          invisible: true, // Make the backdrop invisible
        },
      }}
      PaperProps={{
        elevation: 4, // Remove the shadow
        style: {
          width: "430px",
          // top: drawerTop < 0 ? 0 : drawerTop,
          top:"40px",
          backgroundColor: "var(--merino-color)",
          padding: "32px 32px 16px",
          boxSizing: "border-box",
        },
        sx: {
          "@media (max-width: 850px)": {
            width: "60% !important",
            top:"40px",
          },
          "@media (max-width: 550px)": {
            width: "70% !important",
            top:"40px",
          },
        },
      }}
      SlideProps={{
        appear: false,
      }}
    >
      {/* Drawer content goes here */}
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
        onMouseLeave={onClose}
      >
        <Typography
          variant="h6"
          onClick={() => {
            onClose();
            navigate("/help");
          }}
          sx={{
            cursor: "pointer",
          }}
        >
          {t("HelpDrawer.heading")}
        </Typography>
        <List>
          <ListItemButton
            onClick={() => {
              onClose();
              navigate("/help/shipping");
            }}
          >
            <ListItemText primary={t("HelpDrawer.list.item1")}></ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              onClose();
              navigate("/help/return-and-exchange");
            }}
          >
            <ListItemText primary={t("HelpDrawer.list.item2")}></ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              onClose();
              navigate("/ticket");
            }}
          >
            <ListItemText primary={t("HelpDrawer.list.item3")}></ListItemText>
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              onClose();
              navigate("/help/all-faqs");
            }}
          >
            <ListItemText primary={t("HelpDrawer.list.item4")}></ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              onClose();
              navigate("/help/contact-us");
            }}
          >
            <ListItemText primary={t("HelpDrawer.list.item5")}></ListItemText>
          </ListItemButton>
        </List>
        <Divider className="divider" />
        <Typography
          variant="body1"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        >
          {t("HelpDrawer.subheading")}
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  alt="green iguana"
                  width="100%"
                  // image="/images/demo/Jewelry_Care.webp"
                  // image="img/helpImg/h7.jpeg"
                  image="/img/helpImg/converth7.webp"
                />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="div">
                    {t("HelpDrawer.card1.heading")}
                  </Typography>
                  <Typography variant="body2">
                    {t("HelpDrawer.card1.text")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  alt="green iguana"
                  width="100%"
                  // image="/images/demo/Styling_Appointment.webp"
                  // image="img/helpImg/h3.jpeg"
                  image="/img/helpImg/changh3.webp"
                />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="div">
                    {t("HelpDrawer.card2.heading")}
                  </Typography>
                  <Typography variant="body2">
                    {t("HelpDrawer.card2.text")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export const MainHeaderDrawer: React.FC<MainDrawerProps> = ({
  open,
  data,
  onClose,
  showSubHeader
}) => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const [drawerTop, setDrawerTop] = useState(105);





  useEffect(() => {
    const handleScroll = () => {
      setDrawerTop(105 - window.scrollY);
    };
    const handleResize = () => {
      // Handle resize logic here
      if (window.innerWidth <= 600) {
        setDrawerTop(50 - window.scrollY);
      }
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <Drawer
      anchor="top"
      open={open}
      onClose={onClose}
      className="mainHeaderDrawer"
      hideBackdrop
      PaperProps={{
        elevation: 0, // Remove the shadow
        style: {
          // width: "430px",
          top: drawerTop < 0 ? (showSubHeader ? "100px":"60px") : drawerTop,
          backgroundColor: "var(--merino-color)",
          padding: "32px 32px 16px",
          boxSizing: "border-box",
        },
      }}
      SlideProps={{
        appear: false,
      }}
    >
      {/* Drawer content goes here */}
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
        onMouseLeave={onClose}
      >
        <Grid container spacing={4}>
          <Grid item xs={6} key={"syling_list"}>
            <Grid container sx={{ justifyContent: "flex-end" }} spacing={4}>
              {data.styles?.map((item: any) => (
                <Grid item key={item.id}>
                  <Typography
                    variant="body1"
                    className="ListSubHeading underline-right-dark"
                    key={item.id}
                    onClick={() => {
                      onClose();
                      navigate(`/products/${data?.key}/${item.key}`);
                    }}
                  >
                    {item.name}
                  </Typography>
                  <List>
                    {item.subCategories?.map((subItem: any) => (
                      <ListItem
                        className="mainListItem underline-right-dark"
                        key={subItem.id}
                        onClick={() => {
                          onClose();
                          navigate(
                            `/products/${data?.key}/${item.key}/${subItem.key}`
                          );
                        }}
                      >
                        <ListItemText
                          primary={subItem.name}
                          key={`${subItem.id}name`}
                        ></ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Grid container spacing={2}>
                <Grid item>
                  <img
                    // src="/images/demo/Peircing_Studio.webp"
                    src="/img/headerImg/hd7.jpg"
                    alt="img"
                    style={{ width: "200px" }}
                  />
                  <Typography gutterBottom variant="body1" component="div">
                    Divine Empyeran Stdio
                  </Typography>
                </Grid>
                <Grid item>
                  <img
                    // src="/images/demo/Styling_Appointment.webp"
                    src="/img/headerImg/hd6.jpg"
                    alt="img"
                    style={{ width: "200px" }}
                  />
                  <Typography gutterBottom variant="body1" component="div">
                    Styling Appointment
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export const SignInDrawer: React.FC<DrawerProps> = ({ open, onClose, showSubHeader }) => {
  const { t } = useTranslation();
  const [authUser, setAuthUser] = useState<any>(null);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem(appShortName + "user");
    localStorage.removeItem(appShortName + "token");
    localStorage.removeItem(appShortName + "cart");
    localStorage.removeItem(appShortName + "orderDetails");
    console.log("logout");
    navigate("/");
  };
  useEffect(() => {
    const userData = localStorage.getItem(appShortName + "user");
    if (userData) {
      try {
        const user = JSON.parse(userData);
        setAuthUser(user);
      } catch (error) {
        console.error("Error parsing user data:", error);
        setAuthUser(null);
      }
    } else {
      setAuthUser(null);
    }
  }, [localStorage.getItem(appShortName + "user")]);

  const [drawerTop, setDrawerTop] = useState(105);
  useEffect(() => {
    const handleScroll = () => {
      setDrawerTop(100 - window.scrollY);
    };

    const handleResize = () => {
      // Handle resize logic here
      if (window.innerWidth <= 599) {
        if (window.innerWidth <= 511) {
          if (window.innerWidth <= 332) {
            return setDrawerTop(97 - window.scrollY);
          }
          return setDrawerTop(97 - window.scrollY);
        }
        return setDrawerTop(89 - window.scrollY);
      } else setDrawerTop(105 - window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="storeAndServices"
      ModalProps={{
        BackdropProps: {
          invisible: true, // Make the backdrop invisible
        },
      }}
      PaperProps={{
        elevation: 0, // Remove the shadow
        style: {
          width: "400px",
          height: "auto",
          // top: drawerTop < 0 ? '65px' : drawerTop,
          top: drawerTop < 0 ? (showSubHeader ? "100px":"60px") : drawerTop,
          backgroundColor: "var(--merino-color)",
          padding: "25px 25px 10px",
          boxSizing: "border-box",
        },
        sx: {
          "@media (max-width: 850px)": {
            width: "50% !important", // Example change for smaller screens
          },
          "@media (max-width: 550px)": {
            width: "60% !important", // Example change for smaller screens
          },
        },
      }}
      SlideProps={{
        appear: false,
      }}
    >
      {/* Drawer content goes here */}
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
        onMouseLeave={onClose}
      >
        <Typography variant="h5" mb={1} className="signInHeading">
          {t("SignInDrawer.heading")} {authUser && authUser.name}
        </Typography>
        <Typography variant="body1"  mb={1}>
          {t("SignInDrawer.text1")}
        </Typography>
        {/* user profile and order history and  messages*/}
        {authUser && (
          <Grid container spacing={1} pt={1} pb={1}>
            {/* <Grid item xs={12} sm={6} md={4} lg={4}>
              <Box onClick={() => navigate("/profile")} sx={{display:"flex",gap:"5px",justifyContent:{xs:"flex-start",lg:"center"},cursor:"pointer"}}>
                <AccountCircleIcon sx={{fontSize:{xs:"30px",lg:"20px"}}}/>
                <Typography variant="body1" sx={{display: 'inline',borderBottom:"1px solid black",fontSize:{xs:"1.1rem",lg:"0.9rem"},fontWeight:"700"}}>
                  View Profile
                </Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box onClick={() => {
              navigate("/orders");
              onClose()}} sx={{display:"flex",gap:"5px",justifyContent:{xs:"flex-start",lg:"center"},alignItems:"center",cursor:"pointer"}}>
                <HistoryIcon sx={{fontSize:{xs:"30px",lg:"20px"}}}/>
                <Typography variant="body1" sx={{display: 'inline',borderBottom:"1px solid black",fontSize:{xs:"1.1rem",lg:"0.9rem"},fontWeight:"700"}}>
                  Order History
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
            {/* <Box onClick={() => navigate("#")} sx={{display:"flex",gap:"5px",justifyContent:{xs:"flex-start",lg:"center"},cursor:"pointer"}}>
                <MessageIcon sx={{fontSize:{xs:"30px",lg:"20px"}}}/>
                <Typography variant="body1" sx={{display: 'inline',borderBottom:"1px solid black",fontSize:{xs:"1.1rem",lg:"0.9rem"},fontWeight:"700"}}>
                  Messages
                </Typography>
              </Box> */}
            </Grid>
          </Grid>
        )}
        {/*  */}

        <Button
          fullWidth
          disableRipple
          className="signInBtn"
          sx={{ margin: "5px 0px" }}
          onClick={() => {
            onClose();
            if (authUser) {
              logout();
            } else {
              navigate("/signin");
            }
          }}
        >
          {authUser
            ? t("SignInDrawer.logout.button")
            : t("SignInDrawer.button")}
        </Button>
        <Typography
          variant="body1"
          className="signInText"
          my={2}
          align="center"
        >
          {t("SignInDrawer.text2")}&nbsp;
          {authUser ? (
            ""
          ) : (
            <Typography
              onClick={() => navigate("/signup")}
              variant="body1"
              component="a"
              className="signInText"
            >
              {t("SignInDrawer.text2.a")}
            </Typography>
          )}
        </Typography>
      </Box>
    </Drawer>
  );
};
