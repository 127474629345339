import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../../layout/Api/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Grid,
  Typography,
  Badge,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import "./ProductList.scss";
import ProductListFilter, { FilterProps } from "./ProductListFilter";
import { BASE_URL } from "../../../constants/ApiConstants";
import { useCountryContext } from "../../Public";
import { useTranslation } from "react-i18next";
import { Seodata } from "../../../seo/seoData";
import HelmetSeo from "../../../seo/HelmetSeo";
import { useAddToWishList } from "../wishlist/AddWishlist";
import ProductCards from "./ProductCards";

// page start
function ProductList(this: any) {
  const { categoryKey, styleKey, subCategoryKey } = useParams();
  const [productsList, setProductsList] = useState<any[]>([]);
  const [featuredData, setFeaturedData] = useState<any[]>([]);
  const [sortOption, setSortOption] = useState<any>('featured');
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [pagination, setPagination] = useState({ limit: 20, page: 1 });
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<FilterProps>({
    color: [],
    metal: [],
    price: "",
    stone: [],
    sortBy: "",
  });

  const { addToWishList } = useAddToWishList();
  const { currentCountry } = useCountryContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // const prevPageRef = useRef(0);
  const prevCategoryKeys = useRef({
    categoryKey: "",
    styleKey: "",
    subCategoryKey: "",
  });
  const prevLanguageAndCountry = useRef({
    country: currentCountry?.code,
    language: i18n.language,
  });
  const noMoreData = useRef(false);

  const path = location.pathname;

  useEffect(() => {
    if (path.includes("/products/")) {
      resetProductsState();
    }
  }, [path]);

  useEffect(() => {
    const fetchFeaturedData = async () => {
      if (currentCountry) {
        setLoading(true);

        let url = `products/featured?country=${currentCountry.code}&language=${i18n.language}`;

        if (categoryKey) {
          url += `&categoryKey=${categoryKey}`;
          if (styleKey) {
            url += `&styleKey=${styleKey}`;
            if (subCategoryKey) {
              url += `&subCategoryKey=${subCategoryKey}`;
            }
          }
        }

        try {
          const response = await get(url);
          if (response && response.status === "OK") {
            setFeaturedData(response.data);
          }
        } catch (error) {
          console.error("Error fetching featured data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    if (sortOption === 'featured') {
      fetchFeaturedData();
    } else {
      setFeaturedData([]);
      const fetchData = async () => {
        if (currentCountry) {
          setLoading(true);

          const data_filter: any = { keyword: "" };
          let url = `products/search?limit=${pagination.limit}&page=${pagination.page}&country=${currentCountry.code}&language=${i18n.language}`;

          if (categoryKey) {
            url += `&categoryKey=${categoryKey}`;
            if (styleKey) {
              url += `&styleKey=${styleKey}`;
              if (subCategoryKey) {
                url += `&subCategoryKey=${subCategoryKey}`;
              }
            }
          }

          if (filter) {
            const { sortBy, ...filter_fields } = filter;
            Object.assign(data_filter, filter_fields);
            if (sortBy) {
              url += `&sortBy=${sortBy}`;
            }
          }

          try {
            const response = await post(data_filter, url);
            if (response && response.status === "OK") {
              // handleResponseData(response.data.data, pagination.limit);
              setProductsList(response.data.data);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        }
      };

      fetchData();
    }
  }, [
    currentCountry,
    categoryKey,
    styleKey,
    subCategoryKey,
    i18n.language,
    sortOption
  ]);

  const resetProductsState = () => {
    localStorage.removeItem("productsList")
    localStorage.removeItem("currentPage")
    setPagination({ page: 1, limit: 20 });
    setProductsList([]);
    noMoreData.current = false;
  };

  const loadProductsFromLocalStorage = () => {
    const storedProducts = JSON.parse(
      localStorage.getItem("productsList") || "[]"
    );
    const storedPage = parseInt(localStorage.getItem("currentPage") || "1", 10);

    if (storedProducts.length > 0) {
      setProductsList(storedProducts);
      setPagination((prev) => ({ ...prev, page: storedPage }));
      setHasMore(true);
    }
  };

  useEffect(() => {
    loadProductsFromLocalStorage();
  }, []);

  // const resetFilterParams = () => {
  //   resetProductsState();
  //   setFilter({
  //     color: [],
  //     metal: [],
  //     price: "",
  //     stone: [],
  //     sortBy: "",
  //   });
  //   prevLanguageAndCountry.current = {
  //     country: currentCountry?.code,
  //     language: i18n.language,
  //   };
  //   prevCategoryKeys.current = {
  //     categoryKey: categoryKey || "",
  //     styleKey: styleKey || "",
  //     subCategoryKey: subCategoryKey || "",
  //   };
  // };

  useEffect(() => {
    const fetchData = async () => {
      if (currentCountry) {
        setLoading(true);

        // Check if products are already in local storage
        const storedProducts = JSON.parse(
          localStorage.getItem("productsList") || "[]"
        );
        if (storedProducts.length > 0 && pagination.page === 1) {
          // If there are stored products and we are on the first page, use them
          setProductsList(storedProducts);
          setLoading(false);
          return; // Exit early, no need to fetch
        }

        const data_filter: any = { keyword: "" };
        let url = `products/search?limit=${pagination.limit}&page=${pagination.page}&country=${currentCountry.code}&language=${i18n.language}`;

        if (categoryKey) {
          url += `&categoryKey=${categoryKey}`;
          if (styleKey) {
            url += `&styleKey=${styleKey}`;
            if (subCategoryKey) {
              url += `&subCategoryKey=${subCategoryKey}`;
            }
          }
        }

        if (filter) {
          const { sortBy, ...filter_fields } = filter;
          Object.assign(data_filter, filter_fields);
          if (sortBy) {
            url += `&sortBy=${sortBy}`;
          }
        }

        try {
          const response = await post(data_filter, url);
          if (response && response.status === "OK") {
            handleResponseData(response.data.data, pagination.limit);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [
    pagination.page,
    pagination.limit,
    currentCountry,
    categoryKey,
    styleKey,
    subCategoryKey,
    filter,
    i18n.language,
    sortOption
  ]);

  const handleResponseData = (data: any[], limit: number) => {
    if (data.length > 0) {
      // Directly update the products list and local storage
      setProductsList((prevData) => {
        const updatedList = [...prevData, ...data]; // Concatenate the new data
        localStorage.setItem("productsList", JSON.stringify(updatedList)); // Update local storage
        return updatedList; // Return the updated list
      });
      setHasMore(data.length === limit); // Set hasMore based on the length of new data
    } else {
      noMoreData.current = true; // No more data from API
      setHasMore(false); // Update hasMore state
    }
  };

  const loadMoreData = () => {
    if (hasMore) {
      setPagination((prev) => {
        const newPage = prev.page + 1;
        localStorage.setItem("currentPage", newPage.toString());
        return { ...prev, page: newPage };
      });
    }
  };

  const handleAddToWishList = async (productId: string): Promise<boolean> => {
    const success = await addToWishList(productId);
    if (success) {
      console.log("Added to wishlist successfully!");
    }
    return success;
  };

  const handleProductClick = (element: any) => {
    localStorage.setItem(`scrollPosition-${path}`, window.scrollY.toString());
    if (element.abbrevation) {
      navigate(`/product/${element.abbrevation}`);
    }
  };

  const setSortOptionValue = (value: any) => {
    setSortOption(value);
    setFilter((prev: any) => ({ ...prev, sortBy: value }));
  }

  const easeInOutQuad = (t: number) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const smoothScrollTo = (target: number) => {
    const duration = 800;
    const start = window.scrollY;
    const distance = target - start;
    const startTime = performance.now();

    const animateScroll = () => {
      const currentTime = performance.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const ease = easeInOutQuad(progress);

      window.scrollTo(0, start + distance * ease);

      if (Math.abs(window.scrollY - target) > 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  useEffect(() => {
    const savedPosition = localStorage.getItem(`scrollPosition-${path}`);
    if (savedPosition) {
      smoothScrollTo(parseInt(savedPosition, 10));
      localStorage.removeItem(`scrollPosition-${path}`);
    } else {
      window.scrollTo(0, 0);
    }
  }, [path]);

  const normalizeUrl = (url: string) => url.replace(/\/$/, "");

  const defaultSeo = {
    title: "Default Title",
    description: "Default Description",
    keywords: "default, keywords",
    canonical: "https://defaultcanonicalurl.com",
    robots: "index, follow",
  };
  const [pageOfSeo, setPageOfSeo] = useState<any>(defaultSeo);

  useEffect(() => {
    const normalizedCurrentUrl = normalizeUrl(BASE_URL + location.pathname);
    const currentPage = Seodata.pages.find(
      (page) => normalizeUrl(page.pageUrl) === normalizedCurrentUrl
    );
    setPageOfSeo(currentPage);
  }, [location.pathname]);

  return (
    <Box sx={{ minHeight: "80vh" }}>
      {pageOfSeo && (
        <HelmetSeo
          title={pageOfSeo.title}
          description={pageOfSeo.description}
          keywords={pageOfSeo.keywords}
          canonicalUrl={pageOfSeo.canonical}
          robots={defaultSeo.robots || "index, follow"} // Set default robots value if not provided
        />
      )}

      <Box className="productsList_section">
        <Box mb={2} className="productList_heaing">
          <Badge
            badgeContent={
              <Typography
                variant="body2"
                style={{ fontSize: "16px", color: "#999" }}
              ></Typography>
            }
          >
            <Typography variant="h4" className="title_heading">
              {categoryKey || "All category"}
            </Typography>
          </Badge>
          <Typography
            variant="body1"
            my={2}
            sx={{ fontFamily: "var(--font-family2)" }}
          >
            Elevate your jewelry ensemble with silver and gold&nbsp;
            <Typography
              variant="body1"
              component="span"
              sx={{
                textTransform: "lowercase",
                fontFamily: "var(--font-family2)",
              }}
            >
              {categoryKey?.replace(/_/g, " ") || "all category"}.
            </Typography>
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px" }}>
            <Button
              variant="contained"
              className="filter_btn"
              onClick={() => setFilterDrawer(true)}
            >
              ALL FILTERS + SORT
            </Button>

            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Sort By:
              </Typography>
              <Select
                value={sortOption}
                onChange={(e) => setSortOptionValue(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ minWidth: 150 }}
              >
                <MenuItem value="featured">Featured</MenuItem>
                <MenuItem value="priceAsc">Price: Low to High</MenuItem>
                <MenuItem value="priceDesc">Price: High to Low</MenuItem>
                <MenuItem value="newest">Newest Arrivals</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
        <Box>
          {featuredData?.length ? (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: '25px', fontWeight: 'bold', mb: 2 }}>
                  Featured Products
                </Typography>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {featuredData.map((element, index) => (
                  <Grid
                    item
                    key={element.id + `${index}`}
                    xs={6}
                    sm={3}
                    lg={3}
                    sx={{ padding: "5px" }}
                  >
                    <ProductCards
                      data={element}
                      addToWishList={() => handleAddToWishList(element.id)}
                      handleProductDetails={() => handleProductClick(element)}
                    />
                  </Grid>
                ))}
              </Box>
            </Grid>
          ) : null}

          <Grid container>
            <InfiniteScroll
              dataLength={productsList.length}
              next={loadMoreData}
              hasMore={hasMore}
              loader={<h4 style={{ color: "transparent" }}>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <Typography variant="body1" sx={{ fontSize: '25px', fontWeight: 'bold' }}>
                All Products
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {productsList.map((element, index) => (
                  <Grid
                    item
                    key={element.id + `${index}`}
                    xs={6}
                    sm={3}
                    lg={3}
                    sx={{ padding: "5px" }}
                  >
                    <ProductCards
                      data={element}
                      addToWishList={() => handleAddToWishList(element.id)}
                      handleProductDetails={() => handleProductClick(element)}
                    />
                  </Grid>
                ))}
              </Box>
            </InfiniteScroll>
          </Grid>
        </Box>
        <ProductListFilter
          filter={filter}
          setFilter={setFilter}
          open={filterDrawer}
          onClose={() => setFilterDrawer(false)}
        />
        {loading && (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress
              sx={{
                color: "var(--softPink-color)",
              }}
            />
          </Box>
        )}
      </Box>
    </Box >
  );
}

export default ProductList;
