import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "../../layout/Api/Api";
export default function Content({ name }: any) {
  const [data, setData] = useState<any>();
  useEffect(() => {
    if (name !== "all-faqs")
      get(`contents/title/${name}`).then((response: any) => {
        if (response && response.status === "OK") {
          setData(response.data.content);
          // window.scrollTo({ top: 0});
        } else {
          setData(`<h1>${name}</h1>`);
        }
        window.scrollTo({ top: 0 });
      });
  }, [name]);
  return (
    <Container maxWidth="xl">
      {data && (
        <Box
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      )}
    </Container>
  );
}
