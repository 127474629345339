import { Outlet } from "react-router-dom";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Alert, Box, Snackbar } from "@mui/material";
import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { get } from "../layout/Api/Api";
import Splash from "../layout/splashScreen/Splash";
import { CartProvider } from "./context/CartContext";
import WebCookies from "./cookies/WebCookies";
import EmailSnackbarProvider from "../context/EmailSnackbarContext";
import { BrowserRouter as Router } from "react-router-dom";
import { ProductProvider } from "../context/ProductContext";

export interface headerFooterProps {
  countries: any[];
  languages: any[];
  onLanguageChange: (lng: string) => void;
}
interface currentCountryType {
  code: string;
  currencyABN: string;
  currencyIcon: string;
  icon: string;
  name: string;
}

interface SplashContextType {
  setSplashScreen: (value: boolean) => void;
}

type SnackbarContextType = {
  openSnackbar: (message: string, severity: string) => void;
};
type CountryContextType = {
  currentCountry: currentCountryType | null;
  handleCurrentCountry: (cont: currentCountryType) => void;
};
const SnackbarContext = createContext<SnackbarContextType>({
  openSnackbar: () => { },
});

const splashContext = createContext<SplashContextType>({
  setSplashScreen: () => { },
});

const countryContext = createContext<CountryContextType>({
  currentCountry: null,
  handleCurrentCountry: () => { },
});

export const useSnackbar = () => useContext(SnackbarContext);
export const useCountryContext = () => useContext(countryContext);
export const useSplashScreen = () => useContext(splashContext);

function Public() {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [languages, setLanguages] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [isSplash, setIsSplash] = useState(false);
  const [currentCountry, setCurrentCountry] = useState<currentCountryType | null>(null);
  const [snackbarState, setSnackbarState] = useState({
    message: "",
    severity: "severity",
  });
  const isMounted = useRef(false);
  const [webCookiesState, setWebCookiesState] = useState(true);

  const openSnackbar = (message: string, severity: string) => {
    setSnackbarState({
      message: message,
      severity: severity,
    });
    setOpen(true);
  };

  const handleCurrentCountry = (cont: currentCountryType | null) => {
    if (cont) {
      setCurrentCountry(cont);
    }
  };

  // Fetch geolocation data
  useEffect(() => {
    const fetchGeoLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        const userCountry = data.country_name;
        const country = countries.find((item) => item.name === userCountry);
        if (country) {
          let countryJson = {
            code: country.code,
            currencyABN: country.currencyABN,
            currencyIcon: country.currencyIcon,
            icon: country.icon,
            name: country.name
          }
          handleCurrentCountry(countryJson);
          setCurrentCountry(countryJson);
        } else {
          let countryJson = {
            code: "us",
            currencyABN: "USD",
            currencyIcon: "$",
            icon: "USA",
            name: "United States"
          }
          handleCurrentCountry(countryJson);
          setCurrentCountry(countryJson);
        }
      } catch (error) {
        console.error("Error fetching geolocation:", error);
        let countryJson = {
          code: "us",
          currencyABN: "USD",
          currencyIcon: "$",
          icon: "USA",
          name: "United States"
        }
        handleCurrentCountry(countryJson);
        setCurrentCountry(countryJson);
      }
    };

    if (countries.length > 0) {
      fetchGeoLocation();
    }
  }, [countries]);

  // Fetch countries data on mount
  useEffect(() => {
    if (!isMounted.current) {
      const fetchCountries = async () => {
        try {
          const response = await get("countries");
          if (response && response.status === "OK") {
            setCountries(response.data);
          }
        } catch (error) {
          console.error("Error fetching countries:", error);
        }
      };

      fetchCountries();
      getSlugsByLanguageCode("en");
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);


  const closeSnackbar = () => {
    setOpen(false);
  };

  const changeLanguage = (lng: string) => {
    if (!i18n.hasResourceBundle(lng, "translation")) {
      // If resources are not present, fetch them
      getSlugsByLanguageCode(lng);
    } else {
      // If resources are present, simply change the language
      i18n.changeLanguage(lng);
      console.log("i18n1", i18n.language, lng);
    }
  };

  const updateResources = (language: any, newResources: any) => {
    i18n.addResourceBundle(language, "translation", newResources, true, true);
  };

  const getSlugsByLanguageCode = (languageCode: string) => {
    get(`slugs/language/${languageCode}`).then((response: any) => {
      if (response && response.status === "OK") {
        updateResources(languageCode, response.data);
        i18n.changeLanguage(languageCode);
        // console.log("i18n", i18n.language, languageCode);
      }
    });
  };

  const setSplashScreen = (value: boolean) => {
    setIsSplash(value);
  };

  return (
    <>
    <Box sx={{ overflow: "hidden" }}>

    <ProductProvider>
      <EmailSnackbarProvider>
      <SnackbarContext.Provider value={{ openSnackbar }}>
        <splashContext.Provider value={{ setSplashScreen }}>
          <countryContext.Provider
            value={{ currentCountry, handleCurrentCountry }}
          >
            <CartProvider>
              <Header
                countries={countries}
                languages={languages}
                onLanguageChange={changeLanguage}
              />
              <Box sx={{ minHeight: "80vh"}}>
                <Outlet />
              </Box>
              <Footer
                countries={countries}
                languages={languages}
                onLanguageChange={changeLanguage}
              />
            </CartProvider>
          </countryContext.Provider>
        </splashContext.Provider>
      </SnackbarContext.Provider>
      </EmailSnackbarProvider>
      </ProductProvider>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ width: "100%", top: "0 !important" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbarState.severity as any}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
      {isSplash && <Splash open={isSplash} />}
      {webCookiesState && <WebCookies
        open={webCookiesState}
        onClose={() => {
          setWebCookiesState(false);
        }}
      />}
    </Box>
    </>  
  );
}

export default Public;
