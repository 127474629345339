import React, { FC, useEffect } from "react";
import "./Splash.scss";
import logo1 from "../../assets/faviconbg.png";
import { Box } from "@mui/material";

const Splash: FC<{ open?: boolean }> = ({ open = false }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);
  return (
    <Box className="splash-screen">
      <Box className="logo">
        <Box className="image_container">
          <img src={logo1} style={{ height: "60%" }} alt="logo" />
        </Box>
      </Box>
      <Box className="dot-flashing"></Box>
    </Box>
  );
};

export default Splash;
